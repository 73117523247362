import { Injectable } from "@angular/core";
import { Chart } from 'chart.js';



@Injectable({ providedIn: 'root' })

export class TaboardConfigService {
    constructor() {}
      
      public getChartPieOption = () => ({
        animation: false,
        events: ['mousemove', 'mouseout'/*, 'click', 'touchstart', 'touchmove'*/],
        cutoutPercentage: 70,
        legend: {
          display: false
        },

        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest'
        },

        scales: {
          yAxes: [
            {
              display: 0,
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: 'transparent',
                color: 'rgba(255,255,255,0.05)'
              }
            }
          ],
          xAxes: [
            {
              display: 0,
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: 'transparent'
              },
              ticks: {
                display: false,
                suggestedMin: 0,
              }
            }
          ]
        }
      })

      public getConfigDataChart = (labels: string[], data: any[], gradientStroke: any, options:any, bC: string, pbC: string) => ({
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "My First dataset",
              fill: true,
              backgroundColor: gradientStroke,//Color Rectangle intern
              hoverBackgroundColor: gradientStroke,
              borderColor: bC,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: pbC,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: pbC,
              pointBorderWidth: 20,
              pointRadius: 4,
              data
            }
          ]
        },
        options
      })

      public getMetragesDataPerSite = (array: any[],color: string, graientStroke: any) => ({
        label: 'Countries',
        fill: true,
        backgroundColor: color,
        hoverBackgroundColor: graientStroke,
        borderColor: color,
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        data: [
          array[0].siteM2s?.find(site => site.siteName === 'Tetouan')?.m2?.toString(),
          array[0].siteM2s?.find(site => site.siteName === 'Berrechid')?.m2?.toString(),
          array[0].siteM2s?.find(site => site.siteName === 'Casablanca')?.m2?.toString(),
          array[0].siteM2s?.find(site => site.siteName === 'Kenitra')?.m2?.toString(),
        ]
      })

      public dataCharts = (listData: any[],typeCount: string ,bC: string, gradientStroke: any) => (
        {
          label: 'Countries',
        fill: true,
        backgroundColor: bC,
        hoverBackgroundColor: gradientStroke,
        borderColor: bC,
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        data: [listData[0]?.m2ComptageDAO[typeCount], listData[1]?.m2ComptageDAO[typeCount], listData[2]?.m2ComptageDAO[typeCount], listData[3]?.m2ComptageDAO[typeCount]]

        }
      )

      public getDataSetperYear = (gradientStroke: any, bC: string ,listArray?: any[], restDates?: any[] ,index?: number) => ({
        label: 'Data',
        fill: true,
        backgroundColor: gradientStroke,
        borderColor: bC,
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: 'rgb(255, 205, 86)',
        pointBorderColor: 'rgba(255,255,255,0)',
        pointHoverBackgroundColor: "#ec250d",
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
        data: [
          listArray[index]?.yearM2List.find(year => year.year === 2021)?.m2,
          listArray[index]?.yearM2List.find(year => year.year === 2020)?.m2,
          listArray[index]?.yearM2List.find(year => year.year === 2019)?.m2,
          restDates[index]?.yearM2List?.m2,
        ]
      })


     public getGlobalChartConfig = (data: any[], color: string) => ({
      label: 'Data',
      fill: true,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: 'rgb(255, 205, 86)',
      pointBorderColor: 'rgba(255,255,255,0)',
      pointHoverBackgroundColor: "#ec250d",
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4,
      data
    })

    public getPaletteConfigChart = (gradientStroke: any, data: number[], color: string) => ({
      label: 'Countries',
      fill: true,
      backgroundColor: color,
      hoverBackgroundColor: gradientStroke,
      borderColor: color,
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      data
    })

      public  getGradientBarChartConfigurationRED = (fillColor: string, maxValue: number) => ({
        animation: {
          duration: 10,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            var fontSize = 12;
            var fontStyle = 'bold';
            var fontFamily = 'Helvetica Neue';
            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
            ctx.fillStyle = fillColor;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                if (data != undefined) {
                  var newData = data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                }
              });
            });
          }
        },
        events: [''],
        maintainAspectRatio: false,
        legend: {
          display: false
        },
  
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 0,
          xPadding: 0,
          mode: "nearest",
          intersect: 2,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: maxValue,
                padding: 30,
                fontColor: "#9e9e9e"
              }
            }
          ],
  
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 0,
                fontColor: "#9e9e9e"
              }
            }
          ]
        }
      })

      public getGradientBarChartConfiguration = (color: string, maxValue: number) => ({
        animation: {
          duration: 10,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            var fontSize = 12;
            var fontStyle = 'bold';
            var fontFamily = 'Helvetica Neue';
            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
            ctx.fillStyle = color;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
  
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                if (data) {
                  data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                }
              });
            });
          }
        },
        events: [''],
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 5,
          xPadding: 10,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: maxValue * 3/2,
                padding: 2,
                fontColor: "#9e9e9e"
              }
            }
          ],
  
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }
          ]
        }
      })

      public getGradientBarChartConfigurationPirple = (maxValue: number) => ({
        animation: {
          duration: 10,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            var fontSize = 12;
            var fontStyle = 'bold';
            var fontFamily = 'Helvetica Neue';
            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
            ctx.fillStyle = '#ab87f4'
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            
  
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                if (data) {
                  var newData = data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                }
  
              });
            });
          }
        },
        events: [''],
        maintainAspectRatio: false,
        legend: {
          display: false
        },
  
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 10,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: maxValue,
                padding: 2,
                fontColor: "#9e9e9e"
              }
            }
          ],
  
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }
          ]
        }
      })

      public getGradientBarChartConfigurationGrid = (maxValue: number) => ({
        animation: {
          duration: 10,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            var fontSize = 12;
            var fontStyle = 'bold';
            var fontFamily = 'Helvetica Neue';
            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
            ctx.fillStyle = '#f39999'
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach((dataset, i) => {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                var data = dataset.data[index];
                if (data != undefined) {
                  var newData = data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                }
              });
            });
          }
        },
        events: [''],
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: maxValue,
                padding: 2,
                fontColor: "#9e9e9e"
              }
            }
          ],
  
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }
          ]
        }
      });

      public getChartPieConfig = (labels: string[], site: { bon: string, moyen: string, mauvais: string }, config: any) => ({
        type: 'pie',
        data: {
          labels,
          datasets: [
            {
              label: 'Métrage',
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ['#90a955', '#227c9d', '#b7094c'],
              borderWidth: 0,
              data: [site.bon, site.moyen, site.mauvais]
            }
          ]
        }, options: config
      })
}