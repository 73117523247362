import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/service/authentification.service';
import { OperationService } from 'src/service/operation.service';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  public operations: any[];
  public pagination = { page: 0, size: 8, total: 0 };
  public isLoading = false;

  constructor(private authentificationService:AuthentificationService, private operationService: OperationService) {   }

  ngOnInit(): void {
    this.getOperations();
  }

  public changePage = (page: any): void => {
    this.pagination.page = page.pageIndex;
    this.pagination.size = page.pageSize;
    this.getOperations();
  }

  getOperations(){
    this.isLoading = true;
    const pagination = { page: this.pagination.page.toString(), size: this.pagination.size.toString() };
    this.operationService.getOperations(pagination).subscribe((resp:any) => {
          this.operations = resp.content;
          this.pagination.total = resp.totalElements;
          this.isLoading = false;
        });
  }
}
