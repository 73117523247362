import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthentificationService } from '../authentification.service';

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {
    constructor(private authService: AuthentificationService, private router: Router) { }

    canActivate = (): Observable<any | boolean> | Promise<any> | boolean => {
       return this.authService.loadToken() ? true : this.router.navigate(['/login']);
    }
    
}