import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface Site {
    id: string,
    site: string
}

@Injectable({ providedIn: 'root' })

export class SiteService {
    constructor(private http: HttpClient) {}
    public sites = new BehaviorSubject<Site[]>(null);

    public createSites = (sites: string[]): Observable<any> => this.http.post<any>(`${environment.api_url}/api/inventory/sites`, sites);

    public updateSite = (data: Site): Observable<any> => 
    this.http.put<any>(`${environment.api_url}/api/inventory/sites`, data);

    public deleteSite = (id: number): Observable<any> => this.http.delete<any>(`${environment.api_url}/api/inventory/sites/${id}`); 

    public getSites = (): Observable<Site[]> => this.http.get<Site[]>(`${environment.api_url}/api/inventory/sites`);
} 