import { CommonModule } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ModalModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { QuicklinkModule } from 'ngx-quicklink';
import { ToastrModule } from "ngx-toastr";
import { AuthentificationService } from '../service/authentification.service';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { OperationsComponent } from './pages/exemples/operations/operations.component';
import { ProfilComponent } from "./pages/exemples/profil/profil.component";
import { SharedModule } from "./shared/shared.module";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {OwlDateTimeModule} from 'ng-pick-datetime';
registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RtlLayoutComponent,
    ProfilComponent,
    OperationsComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    ModalModule,
    AppRoutingModule,
    NgxDatatableModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    ComponentsModule,
    QuicklinkModule,
    NgxChartsModule,
    AngularMultiSelectModule,
    ProgressbarModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    OwlDateTimeModule
  ],
  providers: [AuthentificationService,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
