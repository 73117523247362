import { RouteInfo } from "./sidebar.component";

export const ROUTES: RouteInfo[] = [
        {
          path: "/dashboard",
          title: "tableau de bord",
          type: "link",
          icontype: "tim-icons icon-chart-pie-36",
          rtlTitle: "لوحة القيادة"
        },
        {
          path: "/inventaire",
          title: "Inventaire",
          type: "sub",
          icontype: "tim-icons icon-components",
          collapse: "pages",
          rtlTitle: "صفحات",
          isCollapsed: true,
          children: [
            {
              path: "dashboard",
              rtlTitle: " التسعير ",
              rtlSmallTitle: "ع ",
              title: "Détails d'inventaire",
              type: "link",
              smallTitle: "P"
            },
            {
              path: "list",
              rtlTitle: " التسعير ",
              rtlSmallTitle: "ع ",
              title: "Listes des inventaires",
              type: "link",
              smallTitle: "P"
            },
            
          ]
        },
        {
          path: "/traitement",
          title: "Traitement",
          type: "link",
          icontype: "tim-icons icon-single-copy-04",
          rtlTitle: "لوحة القيادة"
        },
        {
          path: "/operations",
          title: "Operations",
          type: "link",
          icontype: "tim-icons icon-single-copy-04",
          rtlTitle: "لوحة القيادة"
        },
        {
          path: "/rapporteurs",
          title: "Rapporteurs",
          type: "sub",
          icontype: "tim-icons icon-single-02",
          rtlTitle: "لوحة القيادة",
            isCollapsed: true,
            children: [
                {
                    path: "list",
                    rtlTitle: " التسعير ",
                    rtlSmallTitle: "ع ",
                    title: "Listes des rapporteurs",
                    type: "link",
                    smallTitle: "R"
                },
                {
                    path: "rapporteur",
                    rtlTitle: " التسعير ",
                    rtlSmallTitle: "ع ",
                    title: "Détails des rapporteurs",
                    type: "link",
                    smallTitle: "DR"
                },

            ]
        },
        {
          path: "/historique",
          title: "Historique",
          type: "link",
          icontype: "tim-icons icon-refresh-02",
          rtlTitle: "لوحة القيادة"
        },
        {
          path: "/parametres",
          title: "Paramètres",
          type: "link",
          icontype: "tim-icons icon-settings",
          rtlTitle: "لوحة القيادة"
        }
]
