import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { InventoryService } from 'src/service/inventory.service';
import { tap } from 'rxjs/operators';
import { AuthentificationService } from 'src/service/authentification.service';
import swal from "sweetalert2";

@Component({
    selector: 'app-shared-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['./modal.component.scss'],
    animations: [
        trigger('animation', [
            transition('void => *', [
                style({
                    opacity: 0,
                    transform: 'translateY(-50px)'
                }), animate(500)
            ]),
        ])
    ]
})

export class ModalComponent implements OnInit {
    @Input('isAdmin') isAdmin: boolean;
    public modalRef: BsModalRef;
    public message: { message: string, type: string };
    public InventoryForm: FormGroup;
    public inventories: any[] = [];
    public selectedState: string = '';
    public selectedDate: any;
    public activeInventory: any;
    constructor(
        private modalService: BsModalService,
        private inventoryService: InventoryService,
        private authenticationService: AuthentificationService) { }

    ngOnInit() {
        this.initializeCreateInventory();
        this.getActiveInventory();
    }

    public openModal = (template, action?: string): void => {
        const config = { backdrop: 'static', keyboard: false, animated: true };
        this.modalRef = this.modalService.show(template, config as any);
        this.initialData();
        const state = (action as string).split(' ')[0].toString() === 'Start' ? 'progress' : 'close';
        this.getInventoriesByState(state);
    }

    public shouldDisableStartButton = (): boolean => {
        const states = ['Partial Closure', 'NONE'];
        return this.inventories.map(inventory => inventory.state).find(state => states.includes(state)); 
    }
    
    public createInventory = (): void => {
        this.message = { message: '', type: '' };
        const data = { inventoryName: this.InventoryForm.value.inventoryName, state: null };
        this.inventoryService.postInventory(data).subscribe((res) => {
            if (Object.values(res).length) {
                this.InventoryForm.patchValue({ inventoryName: '' });
                this.message = { message: `L'inventaire ${data.inventoryName} a été bien enregistré.`, type: 'success' };
            }
        }, err => {
            if (Object.values(err).length) this.message = { message: err.error.message, type: 'error' };
        });
    }

    public onPopUpModal = (): void => {
        swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          customClass:{
            cancelButton: "btn btn-danger",
            confirmButton: "btn btn-success mr-1",
          },
          confirmButtonText: "Yes, i want to closure.",
          buttonsStyling: false
        })
        .then(result => {
          if (result.value) {
            swal.fire({
              title: "Cloturé !",
              text: `Cet inventaire est en ${this.InventoryForm.value.state} .`,
              icon: "success",
              customClass:{
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false
            });
            this.closure();        
         }
        })
    }

    public update = (): void => {
        this.message = { message: '', type: '' };
        const data = { inventoryName: this.InventoryForm.value.inventoryName, state: 'In progress' };
        this.inventoryService.updateInventory(data).subscribe(res => {
            this.InventoryForm.patchValue({ inventoryName: '' });
            this.getInventoriesByState('progress');
            this.getActiveInventory();
            if (Object.values(res).length) this.message = { message: `The inventory ${data.inventoryName.toUpperCase()} is now ${'IN PROGRESS'} state.`, type: 'success' };
        }, err => {
            this.message = { message: err.error.message || 'Something went wrong, Please check your connection and try again .', type: 'error' }
        })
    }

    public closure = (): void => {
        this.message = { message: '', type: '' };
        const formValue = this.InventoryForm.value;
        const state = this.InventoryForm.value.state.split(' ')[0].toString();
        const definitiveData = { inventoryName: formValue.inventoryName };
        const data = state === 'Definitive' ? definitiveData : this.InventoryForm.value;
        if (state === 'Definitive') {
            this.inventoryService.setInventoryToDefinitiveClosure(data).subscribe((res) => {
                this.successCase(data, 'Definitive');
            }, error => {
                this.message = { message: error.error.message, type: 'error' };
            });
        } else {
            this.inventoryService.updateInventory(data).subscribe(res => {
                if (Object.values(res).length) {
                    this.successCase(data, 'Partial');
                    this.getInventoriesByState('close');
                }
            }, err => {
                this.message = { message: err.error.message, type: 'error' }
            })
        }
    }

    public onSelectState = (value: string): void => {
        this.selectedState = value;
        const reset = { startTime: '', finalTime: '', startDate: '', finalDate: '' };
        this.InventoryForm.patchValue(reset);
    }

    private successCase = (data: { inventoryName: string }, state: string): void => {
        this.message = { message: `The inventory ${data.inventoryName} is in ${state} state`, type: 'success' };
        this.InventoryForm.reset();
         this.InventoryForm.patchValue({ inventoryName: '', state: '' });
    }

    private getInventoriesByState = (state?: string, action?: string): void => {
        this.inventoryService.getClosureInventories(state).subscribe((inventories) => {
            this.inventories = inventories;
        });
    }

    private initializeCreateInventory = (): void => {
        this.InventoryForm = new FormGroup({
            inventoryName: new FormControl('', { validators: Validators.required }),
            state: new FormControl('', { validators: Validators.required })
        })
    }

    private getControlName = (name: string): AbstractControl => this.InventoryForm.get(name);

    public shouldDisableClosureButton = (): boolean =>
        this.getControlName('inventoryName').value && this.getControlName('state').value;


    public shouldDisplayClosureButton = (): boolean => this.isAdmin  && this.activeInventory

        private getActiveInventory = (): void => {
        this.inventoryService.getActiveInventory().subscribe(inventory => {
            this.activeInventory = inventory;
            console.log(this.activeInventory);
        })
    }

    private initialData = (): void => {
        this.InventoryForm.patchValue({ inventoryName: '' });
        this.selectedDate = '';
        this.message = { message: '', type: '' };
        this.InventoryForm.get('state').patchValue(this.selectedState);
    }
}