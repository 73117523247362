import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field'
// import { MatLabelModule } from '@angular/material'
import { NgModule } from "@angular/core";



@NgModule({
    imports: [MatTabsModule, MatProgressSpinnerModule, MatPaginatorModule, MatAutocompleteModule, MatFormFieldModule],
    exports: [MatTabsModule, MatProgressSpinnerModule, MatPaginatorModule, MatAutocompleteModule, MatFormFieldModule]
})


export class MaterialModule { }