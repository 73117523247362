import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { AuthentificationService } from "src/service/authentification.service";
import { SiteService } from 'src/service/site.service';
import { ROUTES } from './menu.items';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
//Menu Items

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: any[];
  public isSiteExist = false;
  private subscription: Subscription;
  constructor(private siteService: SiteService, private userService: AuthentificationService) {
    // this.checkIfSiteExist();

  }

  ngOnInit() {
    this.getUserConnected();
  }

  private getUserConnected = (): void => {  
    this.userService.userData.subscribe(user => {
      if (user) {
        if (user.roles.includes('DG')) this.menuItems = ROUTES.filter(menuItem => menuItem.title !== 'Utilisateurs' && menuItem.title !== 'Operations' && menuItem.title !== 'Paramètres');
        else if (user.roles.includes('DCG')) this.menuItems = ROUTES.filter(menuItem =>  menuItem.title !== 'Paramètres');
        else if (user.roles.includes('DGA')) this.menuItems = ROUTES.filter(menuItem => menuItem.title !== 'tableau de bord' && menuItem.title !== 'Utilisateurs' && menuItem.title !== 'Paramètres');
        else this.menuItems = ROUTES;
      }
    })
  }

  private checkIfSiteExist = (): void => {
    this.subscription = this.siteService.sites.subscribe(sites => {
      this.isSiteExist = sites?.length ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
