import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import {NgCircleProgressModule} from 'ng-circle-progress';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        // canActivate: [AuthGuardService],
        loadChildren:
          () => import('./pages/exemples/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  { path: '', loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule) }, 
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/exemples/pages/pages.module').then(m => m.PagesModule)
      }
    ]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
