import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DxVectorMapModule } from "devextreme-angular";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { InventoryComponent } from "../pages/exemples/inventory/inventory.component";
import { InventoryListComponent } from "../pages/exemples/inventory/list/inventory-list.component";
import { TaboardComponent } from "../pages/exemples/taboard/taboard.component";
import { SharedModule } from "../shared/shared.module";
import { AuthNavbarComponent } from "./auth-navbar/auth-navbar.component";
import { ComponentsRoutingModule } from "./compoents.-routing.module";
import { FixedPluginComponent } from "./fixed-plugin/fixed-plugin.component";
import { FooterComponent } from "./footer/footer.component";
import { HistoryComponent } from "./history/history.component";
import { SelectComponent } from "./history/select/select.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { PictureUploadComponent } from "./picture-upload/picture-upload.component";
import { RtlNavbarComponent } from "./rtl-navbar/rtl-navbar.component";
import { RtlSidebarComponent } from "./rtl-sidebar/rtl-sidebar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { VectorMapComponent1 } from "./vector-map/vector-map.component";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {NgCircleProgressModule} from 'ng-circle-progress';


@NgModule({
    imports: [
        CommonModule,
        ComponentsRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        JwBootstrapSwitchNg2Module,
        DxVectorMapModule,
        NgxChartsModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgCircleProgressModule.forRoot(),
    ],
  declarations: [
    VectorMapComponent1,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    RtlNavbarComponent,
    RtlSidebarComponent,
    FixedPluginComponent,
    HistoryComponent,
    SelectComponent,
    InventoryComponent,
    InventoryListComponent,
    TaboardComponent
  ],
  exports: [
    FooterComponent,
    VectorMapComponent1,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    RtlNavbarComponent,
    RtlSidebarComponent,
    FixedPluginComponent,
  ],
})
export class ComponentsModule {}
