
import { Component, OnInit } from '@angular/core';
import { Inventory, InventoryService } from 'src/service/inventory.service';

@Component({
    selector: 'app-inventory-list',
    templateUrl: 'inventory-list.component.html'
})

export class InventoryListComponent implements OnInit {
    public isLoading = false;
    public inventories: Inventory[] = [];
    constructor(private inventoryService: InventoryService) { }

    ngOnInit() {
        this.getAllInventories();

    }

    public removeInventory = (): void => {

    }

    private getAllInventories = (): void => {
        this.inventoryService.getInventoriesWithAllStates().subscribe(inventories => {
            console.log(inventories);
            this.inventories = inventories?.map(inv => {
                if (!inv.state) inv.state = 'Inventaire non démarré';
                return inv;
            });
            console.log(this.inventories);
        })
    }


}