import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  addEventInventory=0;
  addEventTaboard=0;
  source: any;
  constructor(private zone: NgZone) {}

  public getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  getServerSentEvent(url: string): Observable<MessageEvent> {
    return new Observable(observer => {
      const eventSource = this.getEventSource(`${environment.api_url}${url}`);
      eventSource.addEventListener("LatestNews",()=>{
      })
      eventSource.onmessage = event => {
        this.zone.run(() => observer.next(event) );
      };
    });
  }
}
