import { Component, OnInit } from '@angular/core';
import {User} from "../../../../model/user";
import {AuthentificationService} from "../../../../service/authentification.service";
import {DashboardService} from "../../../../service/dashboard.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

    user : User;
    data: Array<any>;
    private selectedFiles: any;
    public editPhoto: boolean;
    public progress: number;
    private currentFileUpload: any;
    public progressShow: boolean;

  constructor(private router: Router, public authentificationService: AuthentificationService,private dashboardService: DashboardService) {
      if(this.authentificationService.isAuthenticate())
          this.authentificationService.loadToken();
      else this.router.navigateByUrl('/login');
  }

  ngOnInit(): void {
    // @ts-ignore
   this.authentificationService.getUserConnected()
        .subscribe(resp=>{
          // @ts-ignore
          this.user=resp;
          //alert(this.user.id)
        })
  }

  onUpdateProfil() {
    this.authentificationService.updateProfile(this.user)
        .subscribe(resp=>{
        },error => {
        })
  }
    onEditPhoto() {
        this.editPhoto=true;
    }
    onSelectedPhoto(event) {
        this.selectedFiles=event.target.files;
    }
    onUploadPhoto() {
      this.progress = 0;
      this.currentFileUpload = this.selectedFiles.item(0);
      this.authentificationService.uploadPhotoUser(this.currentFileUpload,this.user.id)
          .subscribe(event=>{
              this.progressShow=true;
              if (event.type===HttpEventType.UploadProgress){
                  this.progress = Math.round(100 * event.loaded / event.total);
              }else if (event instanceof HttpResponse){
                  this.editPhoto=false;
                  //this.progressShow=false;
              }
               window.location.reload();
          },error=>{
              alert("Img non enregistrée"+error);
          })
    }
}
