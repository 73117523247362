import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { stringify } from "querystring";
import { AuthentificationService } from "src/service/authentification.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-fixed-plugin",
  templateUrl: "./fixed-plugin.component.html",
  styleUrls: ["./fixed-plugin.component.scss"]
})
export class FixedPluginComponent implements OnInit {
  public sidebarColor: string = "blue";
  public state: boolean = true;
  public dashboardColor: boolean = true;
  public isAdmin = false;
  public isLoading = { action: false, message: '' };
  constructor(public toastr: ToastrService, private authService: AuthentificationService) {}

  progress: number;
  currentFileUpload: any;
  selectedFiles: any;

  changeSidebarColor(color) {
    var sidebar = document.getElementsByClassName("sidebar")[0];
    var mainPanel = document.getElementsByClassName("main-panel")[0];
    this.sidebarColor = color;

    if (sidebar != undefined) {
      sidebar.setAttribute("data", color);
    }
    if (mainPanel != undefined) {
      mainPanel.setAttribute("data", color);
    }
  }
  changeDashboardColor(color) {
    var body = document.getElementsByTagName("body")[0];
    if (body && color === "white-content") {
      body.classList.add(color);
    } else if (body.classList.contains("white-content")) {
      body.classList.remove("white-content");
    }
  }
  ngOnInit() {
    this.getUser();
  }
  onChangeDashboardColor(event) {
    const body = document.getElementsByTagName("body")[0];
    if (this.dashboardColor === true) {
      this.changeDashboardColor("");
    } else {
      this.changeDashboardColor("white-content");
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    var simulateWindowResize = setInterval(function() {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  onChange(event) {
    const body = document.getElementsByTagName("body")[0];
    if (this.state === true) {
      body.classList.remove("sidebar-mini");
      this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add("sidebar-mini");
      this.showSidebarMessage("Sidebar mini activated...");
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    var simulateWindowResize = setInterval(function() {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  private getUser = () => {
    this.authService.userData.subscribe((user: any ) => {
      this.isAdmin = this.authService.isAdmin() ? true : false;
    })  
  }

  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>',
      message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }

  onClickUpload(): void {
    const input = document.createElement('input');
    input.type = "file";
    input.addEventListener('change',e => {this.onSelectFile(e)});
    input.click();
    input.remove();
  }

  onSelectFile(e): void{
    this.selectedFiles = e.target.files;
  }

  onUploadFile(): void {
    this.progress = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.isLoading.action = true;
    this.authService.uploadTheoriqueFile(this.currentFileUpload)
        .subscribe(event=>{
          //console.log(event)
          this.isLoading.action = false;
          this.isLoading.message = 'Upload done .';
        }, (error) => {
        if(error.status+"" === '406'){
          let err:any= JSON.parse(error.error)
          let message:any=err.message
          let m:Array<any>= JSON.parse(message)
          // display only 20
          if(m.length>20){
            m=m.slice(0, 20)
            message=m
            message=message+"..."
            message='['+message+']'
          }
            
          Swal.fire({
            icon: 'error',
            title: 'Erreur ...',
            text: 'Erreur dans les lignes : '+message
          })
        }
         
          this.isLoading.action = false;
          this.isLoading.message = 'Something went wrong with the upload.';
        })
  }
}
