import { Injectable } from "@angular/core";
import Chart from 'chart.js';


@Injectable({ providedIn: 'root' })

export class InventoryConfigService {



  updateScales(chart): void {
    chart.options.scales = {
      xScale: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(233,32,16,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      yScale: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(233,32,16,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    };
    chart.update();
  }

  public getConfigDataForFormatOrChoice = (data: any[], count: string,color?: string) => ({
    label: "Data",
    fill: true,
    backgroundColor: color || "#00d6b4",
    borderColor: color || "#00d6b4",
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: "#00d6b4",
    pointBorderColor: "rgba(255,255,255,0)",
    pointHoverBackgroundColor: "#00d6b4",
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4,
    data: [
      data[0]?.m2ComptageDAO[count],
      data[1]?.m2ComptageDAO[count],
      data[2]?.m2ComptageDAO[count],
      data[3]?.m2ComptageDAO[count],
    ],
  })

 public getChartConfig = (maxValue?: number): any =>  (
  {
    //animation : false,
    animation: {
      duration: 10,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        var fontSize = 12;
        var fontStyle = 'oblique';
        var fontFamily = 'Helvetica Neue';
        ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
        ctx.fillStyle = 'white'
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    },
    events: [''],
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [{

        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: maxValue,
          padding: 1,
          fontColor: "#9e9e9e"
        }
      }],

      xAxes: [{

        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {//Style Etiquette X
          suggestedMin: 0,
          padding: 50,
          fontColor: "#9e9e9e",
          autoSkip: false 
        }
      }]
    }
  }
 )

}
