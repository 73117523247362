import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface Inventory {
    inventoryName: string;
    state?: string;
    dateto?: string;
}

@Injectable({ providedIn: 'root' })

export class InventoryService {

    constructor(private http: HttpClient) { }


    public getInventoriesWithAllStates = (): Observable<any[]> =>
        this.http.get<any[]>(`${environment.api_url}/api/inventory/inventories/all`); 

    public getInventories = (): Observable<any> =>
        this.http.get<any>(`${environment.api_url}/api/inventory/inventories`);

    public getTheoricalFile = (data: { inventoryName: string, site: string }): Observable<any> => {
        return this.http.get(`${environment.api_url}/api/inventory/inventories/c3csv/${data.inventoryName}/${data.site}`, {
            responseType: 'blob',
            observe: 'response',
        });
    }

    public getPerformanceByHour = (count: string, site: string): Observable<any> =>
        this.http.get(`${environment.api_url}/api/inventory/palets/${count}/progress/${site}`)

    public getMetragesPerTypeStock = (data: { count: string, site: string }): Observable<any> =>
        this.http.get(`${environment.api_url}/api/inventory/palets/${data.count}/dashboard/typeStock/${data.site}`);

    public getMetragesPerTypePalettes = (data: { count: string, site: string }): Observable<any> =>
        this.http.get(`${environment.api_url}/api/inventory/palets/${data.count}/dashboard/typePalet/${data.site}`) 

    public getFormatDataPerSite = (site: string): Observable<any> =>
        this.http.get(`${environment.api_url}/api/inventory/dashboard/format/${site}`) 

    public getChoixDataPerSite = (site: string): Observable<any> => 
        this.http.get(`${environment.api_url}/api/inventory/dashboard/choice/${site}`)

    public getMaitragePerMagazin = (site: string): Observable<any> => 
    this.http.get(`${environment.api_url}/api/inventory/dashboard/store/${site}`)

    public getpaletsPerStore = (site: string): Observable<any> =>
        this.http.get(`${environment.api_url}/api/inventory/dashboard/store/pallet/${site}`)

    public postInventory = (inventory: Inventory): Observable<any> =>
        this.http.post<any>(`${environment.api_url}/api/inventory/inventories/new`, inventory);
        
    public updateInventory = (inv: Inventory) =>
        this.http.put(`${environment.api_url}/api/inventory/inventories/update`, inv);

    public setInventoryToDefinitiveClosure = (inventory: Inventory): Observable<any> => {
       return  this.http.put(`${environment.api_url}/api/inventory/inventories/close`, inventory);
    }

    public getMetragesPerYearProd = (site: string): Observable<any> => 
        this.http.get(`${environment.api_url}/api/inventory/dashboard/year/${site}`)
        
    public findByInventory = (inventory: any): Observable<any> =>
        this.http.post<any>(`${environment.api_url}/api/inventory/inventories`, inventory);

    public getActiveInventory = (): Observable<any> =>
        this.http.get<any>(`${environment.api_url}/api/inventory/inventories/inprogress`);

    public getClosureInventories = (state: string): Observable<Partial<Inventory[]>> =>
        this.http.get<Inventory[]>(`${environment.api_url}/api/inventory/inventories/inclosure/${state}`);
}