import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  //host: string = 'http://localhost:8080';
  //host: string = 'http://34.77.14.134:8080';
  // host: string = 'https://localhost:8443';
  villeCurrent;

  constructor(private httpClient: HttpClient) {}

  public getMetrageGlobal = (): Observable<any> => this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/global`)

  public getResource(url: any, httpParams?: { [key: string]: string }): Observable<any> {
    let params = new HttpParams();
    if (httpParams) {
      for (const [key, value] of Object.entries(httpParams)) {
        params = params.set(key, value);
      }
    }
    return this.httpClient.get(`${environment.api_url}${url}`, {  params });
  }

  public getAllFormatPerMaitrage = (): Observable<any> => 
        this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/format/all`)

  public getMetragesPerTypeClients = (): Observable<any> =>
    this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/client/all`); 

  public getMaitragePerYear = (): Observable<any> => this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/year/all`) 
  public getStoresWithMetrages = (site: string): Observable<any> => 
  this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/store/${site}`);
  
  public getMetragesPerState = (): Observable<any> => 
    this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/sites/state`);

    public getC3WithSt = (): Observable<any> => this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/sites/c3theoretical`)

  public getMetragePerSites = (): Observable<any> => 
    this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/sites/all`) 

  public getAllChoixPerMaitrage = (): Observable<any> => 
  this.httpClient.get(`${environment.api_url}/api/inventory/dashboard/choice/all`);

  getFile(url: string) {
    let headers = new HttpHeaders({ 'Authorization': localStorage.getItem('token') });
    return this.httpClient.get(`${environment.api_url}/api/inventory${url}`, { headers: headers, responseType: 'blob' });
  }





  public getProgressBarData = (): Observable<any> =>
      this.httpClient.get(`${environment.api_url}/api/inventory/count/progress`)
}

