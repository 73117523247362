import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthentificationService {
  public userData = new BehaviorSubject<any>(null);
  private jwtToken: string;
  // host: string = 'http://localhost:8080';
  // host: string = "http://34.77.14.134:8080";
  //host: string = 'https://localhost:8443';
  username: string;
  villeCurrent: string;
  sites: any;
  roles: Array<any> = [];

  constructor(private http: HttpClient, private dashboardService: DashboardService, private router: Router) { }
  private token = localStorage.getItem('token') as string
  login(data) {
    return this.http.post(`${environment.api_url}/api/inventory/auth/login`, data, { observe: 'response' });
  }

  register(user) {
    const token = this.loadToken();
    return this.http.post(`${environment.api_url}/api/inventory/auth/register`, user, { headers: new HttpHeaders({ 'Authorization': token }) });
  }

  public findAccount = (data: { [key: string]: string }) => {
    return this.http.post(`${environment.api_url}/api/inventory/auth/forgot-password`, data)
  }
  public getResource(url:any) {
    return this.http.get(`${url}`, {headers:new HttpHeaders({'Authorization':localStorage.getItem('token')})});
  }

  public recoverAccount = (data?: { [key: string]: string }, token?: string) => {
    return this.http.post(`${environment.api_url}/api/inventory/auth/recover-password`, data, { headers: new HttpHeaders({ 'Authorization': token }) })
  }

  public confirmationAccount = (token: string): Observable<any> => {
    const params = `confirmed=${true}&token=${token}`;
    return this.http.get(`${environment.api_url}/api/inventory/auth/register/confirm-account?${params}`);
  } 

  saveData(jwt: string, username: string) {
    this.jwtToken = "Bearer " + jwt;
    localStorage.setItem('token', this.jwtToken);
    localStorage.setItem('username', username);
    this.parseJWT();
  }

  parseJWT() {
    let jwtHelper = new JwtHelper();
    const decodedToken = jwtHelper?.decodeToken(this.jwtToken);
    this.roles = decodedToken.roles;
    this.username = jwtHelper.decodeToken(this.jwtToken).sub;
  }

  public getCurrentCity = (): string => this.villeCurrent; 

  loadToken(): string {
    this.jwtToken = localStorage.getItem('token');
    this.parseJWT();
    return this.jwtToken;
  }
  
  isExpired(){
    let jwtHelper = new JwtHelper();
    return jwtHelper.isTokenExpired(this.jwtToken);
  }

  getRoles()
  {
    if(this.jwtToken==null) this.loadToken();
    return this.http.get(`${environment.api_url}/api/inventory/appRoles`,
    { headers: new HttpHeaders({ Authorization: this.token })});
  }

  logout() {
    localStorage.clear();
    this.initParams();
    this.userData.next(null);
    this.router.navigate(['/pages/login']);

  }

  initParams() {
    this.jwtToken = undefined;
    this.roles = undefined;
    this.username = undefined;
  }

  public isAuthenticate = (): boolean => localStorage.getItem('token') ? true : false;

  isAdmin(): boolean {
    const roles = ['ADMIN', 'DG', 'DCG'];
    return this.isAuthenticate() && this.roles?.find(role => roles.includes(role));
  }

  getUserConnected(username?: string){
    return this.http
      .get<any[]>(`${environment.api_url}/api/inventory/users/`+this.username || username,{headers:new HttpHeaders({'Authorization':localStorage.getItem('token')})})
      .pipe(map(user => ({ ...user, site: (user as any)?.site?.split(' ') })))
  }
  saveTask(task: any) {
    return this.http.post(`${environment.api_url}/api/inventory/appRoles`, task, { headers: new HttpHeaders({ 'Authorization': this.jwtToken }) });
  }

  updateProfile(obj){
        return this.http
                .put<any>(`${environment.api_url}/api/inventory/users/updateProfile`, obj, {headers :new HttpHeaders({'Authorization':localStorage.getItem('token')})});
      }

  uploadPhotoUser(file: File,id){
    let formData:FormData=new FormData();
    formData.append('file',file)

    const req = new HttpRequest('POST','/api/inventory/users/updatePhoto/'+id, formData,{
      reportProgress : true,
      responseType : 'text',
    })
    return this.http.request(req);
  }

    uploadTheoriqueFile(file: File): Observable<any> {
      let formData:FormData=new FormData();
      formData.append('file',file);
      return this.http.post(`${environment.api_url}/api/inventory/users/upload/theoretical`, formData, {
        reportProgress : true,
        responseType : 'text',
        headers : new HttpHeaders({'Authorization':localStorage.getItem('token')})
      });
    }
}
