import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators/map';
import { SiteService } from 'src/service/site.service';
import { AuthentificationService } from '../service/authentification.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit{
  public isConfirmed: { confirmed: boolean, submitted: boolean };
  constructor(private authentificationService: AuthentificationService, private siteService: SiteService) {}

  ngOnInit(): void {
    if(this.authentificationService.isAuthenticate()) {
      this.authentificationService.loadToken();
      const username = localStorage.getItem('username') || '';
      this.authentificationService.getUserConnected(username).pipe(map((user: any) => ({ ...user, roles: user?.roles?.map(role => role.role) })))
      .subscribe(user => {
      this.authentificationService.userData.next(user);
      });
      this.siteService.getSites().subscribe(sites => {
        if (sites?.length) this.siteService.sites.next(sites);
      });
      if(this.authentificationService.isExpired()) this.authentificationService.logout();
    }
  // --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key'",
}
}
