import {
  Component, ElementRef, OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Chart from "chart.js";
import * as _ from "lodash";
/*import * as url from "url";*/
import { Subscription } from "rxjs";
import { HistoryConfigsService } from "src/app/components/history/history-config.service";
import { environment } from "src/environments/environment";
import { InventoryService } from "src/service/inventory.service";
import { Site, SiteService } from "src/service/site.service";
import { SseService } from "src/service/sse.service";
import { AuthentificationService } from "../../../../service/authentification.service";
import { DashboardService } from "../../../../service/dashboard.service";
import { TaboardConfigService } from "./../taboard/taboard.config.service";
import { InventoryConfigService } from "./inventory.config.service";

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
})
export class InventoryComponent implements OnInit, OnDestroy {
  @ViewChild("htmlData") htmlData: ElementRef;

  public canvas: any;
  public ctx;
  public datasets: any;
  public data: any;
  public myChartData;
  public currentCity: string = "";
  public hideDefault:boolean=false;
  public inventory: any;
  public charts = { perf: '', format: '', choix: '',mapP: '', palette: '', etat: '', tS: '', tP: '', year: '', store: '' }
  public selectedGlobalCount: string = "1e/2e";
  public selectedCountForFormat = "Comptage 1";
  public selectedCountForEtat = "Comptage 1";
  public selectedCountPerformance = "Comptage 1";
  public selectedCountForChoice = "Comptage 1";
  public selectedCountForStore = "Comptage 1";
  public selectedCountForMaitrage = "Comptage 1";
  public selectedCountForStock = "Comptage 1";
  public selectedCountForPalets = "Comptage 1";
  public isLoading = false;
  public storesPerSite: { name: string; value: number }[] = [];
  public formatsGlobLabels: string[];
  public formatsGlobData: any[];
  mode: any = 1;
  maitrageCurrenteVille: any = 0;
  palettesCurrenteVille: any = 0;
  etatParVille: any;
  formatParVille: any;
  choixParVille: any;
  storeParSite: any;
  etats: any;
  occurenceEtats: any;
  formats: any;
  occurenceFormats: any;
  choixs: any;
  occurenceChoix: any;
  store: any;
  occurenceStore: any;
  maitrageCurrenteVille2: any = 0;
  palettesCurrenteVille2: any = 0;
  etatParVille2: any;
  formatParVille2: any;
  choixParVille2: any;
  storeParSite2: any;
  maitrageCurrenteVille3: any = 0;
  palettesCurrenteVille3: any = 0;
  maitrageTheorique: any = 0;
  palettesTheorique: any = 0;
  etatParVille3: any;
  formatParVille3: any;
  choixParVille3: any;
  storeParSite3: any;
  public isAdmin: boolean;
  public sites: Site[] = [];
  public userData: any;
  private subscription: Subscription;
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    public authentificationService: AuthentificationService,
    private siteService: SiteService,
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
    private inventoryConfigService: InventoryConfigService,
    private historyService: HistoryConfigsService,
    private sseService: SseService,
    private taboardService: TaboardConfigService,
  ) {
    if (this.authentificationService.isAuthenticate())
      this.authentificationService.loadToken();
    else this.router.navigateByUrl("/pages/login");
  }



  public labelFormatting = (c) => {
    return `${c.label}`;
  };

  //Data Palette C1//Verification Ville Current
  public getMaitrageParSite(site: String) {
    this.dashboardService
      .getResource("/api/inventory/maitrageParSite/" + site)
      .subscribe((data) => {
        this.maitrageCurrenteVille = data;
      });
  }

  public selectCountFormat = (count: string): void => {
    this.selectedCountForFormat = count;
    if (count === "Comptage 1") this.getFormatParSite(this.currentCity);
    else if (count === "Comptage 2") this.getFormatParSiteC2(this.currentCity);
  };

  public selectCountEtat = (count: string): void => {
    this.selectedCountForEtat = count;
    if (count === "Comptage 1") this.getEtatParSite(this.currentCity);
    else if (count === "Comptage 2") this.getEtatParSiteC2(this.currentCity);
  };

  public selectCountForMaitrage = (count: string): void => {
    this.selectedCountForMaitrage = count;
    this.getMaitragePerStore(count);
  };

  public selectCountChoix = (count: string): void => {
    this.selectedCountForChoice = count;
    if (count === "Comptage 1") this.getChoixParSite(this.currentCity);
    else if (count === "Comptage 2") this.getChoixParSiteC2(this.currentCity);
  };

  public selectCountStore = (count: string): void => {
    this.selectedCountForStore = count;
    if (count === "Comptage 1") this.getStoreParSite(this.currentCity);
    else if (count === "Comptage 2") this.getStoreParSiteC2(this.currentCity);
  };

  public selectCountTypeStock = (count: string): void => {
    this.selectedCountForStock = count;
    this.getMetragesDataPerTypeStock(count);
  };

  public selectCountForPerformance = (count: string): void => {
    this.selectedCountPerformance = count;
    this.getPaletsDataByHour(count);
  };

  public selectCountForPalets = (count: string): void => {
    this.selectedCountForPalets = count;
    this.getMetragesDataPerTypePalets(count);
  };

  getPaletsDataByHour(count?: string): void {
    const data = {
      count: `c${count.split(" ")[1]?.toString()}`,
      site: this.currentCity,
    };
    this.inventoryService
      .getPerformanceByHour(data.count, data.site)
      .subscribe((resp) => {
        const data = resp.reverse();
        const heures = (data.map(res => res.hour) as number[]);
        const nbrPalettesHeures = data.map(res => res.total);
        this.dataHourPaletsChartChange(heures, nbrPalettesHeures);
      });
  }

  private getUserData = (): void => {
    this.subscription = this.authentificationService.userData.subscribe(
      (user) => {
        if (user) {
          this.isAdmin = user?.roles.map((role) => role.role).includes("ADMIN");
          this.userData = user;
          this.getSites();
        }
      }
    );
  };

  private getFormatPerSite = (): void => {
    this.inventoryService.getFormatDataPerSite(this.currentCity).subscribe((data) => {
        this.getTop4Formats(data);
      });
  };

  private getActiveInventory = (): void => {
    this.inventoryService.getActiveInventory().subscribe((inventory) => (this.inventory = inventory));
  };

  private getSites = (): void => {
    this.siteService.getSites().subscribe((sites) => {
      this.sites = sites;
      this.authentificationService.userData.subscribe(user => {
        this.sites = this.sites.map(site => ({ id: site.id, site: site.site.toLowerCase() })).filter(site => user.site.map(site => site.toLowerCase()).includes(site?.site))
      })
      this.router.navigate([], {
        queryParams: {
          site: this.sites.find((site) => site.site === "Kenitra".toLowerCase())
            ? "Kenitra"
            : "",
        },
      });
    });
  };

  private getChoixPerSite = (): void => {
    this.inventoryService
      .getChoixDataPerSite(this.currentCity)
      .subscribe((data) => {
        this.getTop4Choix(data);
      });
  };

  private dataHourPaletsChartChange(heures: number[],nbrPaletteHeure: number[]) {
    const gradientChartOptionsConfigurationWithTooltipRed = this.historyService.getPerformanceChart();
    if (this.charts.perf) (this.charts.perf as any).destroy();
    const canvas = document.getElementById("chartHourPalette") as any;
    if (canvas) this.ctx = canvas.getContext("2d");
    const gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "rgba(233,32,16,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(233,32,16,0.0)");
    gradientStroke.addColorStop(0, "rgba(233,32,16,0)"); //red colors
    this.charts.perf = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: heures,
        datasets: [
          {
            label: "",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#ec250d",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ec250d",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ec250d",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: nbrPaletteHeure,
          },
        ],
      },
      options: gradientChartOptionsConfigurationWithTooltipRed,
    });
    this.inventoryConfigService.updateScales(this.charts.perf);
  }

  public getMetragesPerYear = (): void => {
    this.inventoryService
      .getMetragesPerYearProd(this.currentCity)
      .subscribe((dataPerYear) => {
        let years = dataPerYear?.reduce((prev, curr) => [...prev, ...curr.yearM2List ], []);
            years = years.map(year => year.year).filter((year, index) => year >= 2019);
            years = [...new Set(years)];
        let transformedArray = dataPerYear.map((data) => ({
          ...data,
          yearM2List: data.yearM2List.map((year) => ({ ...year, m2: parseInt(year.m2) })),
        }));
        let restDates = transformedArray.map((data) => ({ ...data, yearM2List: data.yearM2List.filter((year, index) => year?.year < 2019) }));
        restDates = restDates.map((date) => ({
          ...date,
          yearM2List: {
            year: "anciennes année",
            m2: date.yearM2List.reduce((prev, curr) => prev + curr.m2, 0),
          },
        }));
        years.push("2018 et antérieur");
        const theorical = (dataPerYear.find(year => year.countingType === 'theoretical').yearM2List as any[]).map(year => parseInt(year.m2));
        const maxValue = Math.max(...theorical);
        const gradientBarChartConfigurationEtat = this.taboardService.getGradientBarChartConfigurationRED("white",maxValue + maxValue / 3);
        const canvas = document.getElementById("maitrageChart1") as any;
        if (canvas) this.ctx = canvas.getContext("2d");
        const data = {
          labels: years,
          datasets: [
            this.taboardService.getDataSetperYear(
              "#a9d6e5",
              "#a9d6e5",
              transformedArray,
              restDates,
              0
            ),
            this.taboardService.getDataSetperYear(
              "#468faf",
              "#468faf",
              transformedArray,
              restDates,
              1
            ),
            this.taboardService.getDataSetperYear(
              "#2a6f97",
              "#2a6f97",
              transformedArray,
              restDates,
              2
            ),
            this.taboardService.getDataSetperYear(
              "#A1EDC9",
              "#A1EDC9",
              transformedArray,
              restDates,
              3
            ),
          ],
        };
        this.charts.year = new Chart(this.ctx, {
          type: "bar",
          data,
          options: gradientBarChartConfigurationEtat,
        });
      });
  };

  public getMetragesDataPerTypePalets = (count?: string): void => {
    const data = {
      count: `c${count.split(" ")[1]?.toString()}`,
      site: this.currentCity,
    };
    this.inventoryService.getMetragesPerTypePalettes(data).subscribe((data:any) => {
      const labels = data?.map((d:any) => d?.typePalet);
      const m2Palettes = data?.map((type) => Number(type.totalM2.toFixed(2)));
      const maxValue =m2Palettes? Math.max(...m2Palettes):0;
      const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 2);
      this.canvas = document.getElementById("ChartTypePalet");
      if (this.canvas) this.ctx = this.canvas.getContext("2d");
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#2A6F97");
      gradientStroke.addColorStop(0.4, "#2A6F97");
      gradientStroke.addColorStop(0, "#2A6F97"); //blue colors
      this.charts.tP = new Chart(
        this.ctx,
        this.configChart(
          labels,
          m2Palettes,
          gradientStroke,
          gradientBarChartConfigurationEtat,
          "#2A6F97"
        )
      );
    });
  };

  private getTop4Choix = (choix: any[]): void => {
    let transformedChoix = choix.map(choix => ({ ...choix, formatOrChoice: Number(choix.formatOrChoice) }))
      ?.sort((a, b) => parseInt(a.formatOrChoice) - parseInt(b.formatOrChoice))
      .filter((format, index) => index <= 3);
    const choixs = transformedChoix.map((format) => format.formatOrChoice).map((choix) => `CHOIX ${choix}`);
    transformedChoix = transformedChoix.map((format) => ({
      ...format,
      m2ComptageDAO: {
        c1: parseInt(format.m2ComptageDAO.c1 || 0),
        c2: parseInt(format.m2ComptageDAO.c2 || 0),
        c3: parseInt(format.m2ComptageDAO.c3 || 0),
        theorical: parseInt(format.m2ComptageDAO.theoretical || 0),
      },
    }));
    const values = transformedChoix.reduce((prev, curr) => {
        return [...prev, ...Object.values(curr.m2ComptageDAO)]
    },[])
    const maxValue = Math.max(...values);
    const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 3);
    this.canvas = document.getElementById("chartChoixLine");
    if (this.canvas) this.ctx = this.canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
    const data = {
      labels: choixs,
      datasets: [
        this.inventoryConfigService.getConfigDataForFormatOrChoice(
          transformedChoix,
          "c1",
          "#a9d6e5"
        ),
        this.inventoryConfigService.getConfigDataForFormatOrChoice(
          transformedChoix,
          "c2",
          "#468faf"
          
        ),
        this.inventoryConfigService.getConfigDataForFormatOrChoice(
          transformedChoix,
          "c3",
          "#2a6f97"
        ),
        this.inventoryConfigService.getConfigDataForFormatOrChoice(
          transformedChoix,
          "theorical",
          "#A1EDC9"
          
        ),
      ],
    };
    this.charts.choix = new Chart(this.ctx, {
      type: "bar",
      data,
      options: gradientBarChartConfigurationEtat,
    });
  };
  private getTop4Formats = (formats: any[]): void => {
    let transformedFormats = formats
      ?.sort((a, b) => parseInt(b.totalC1C2) - parseInt(a.totalC1C2))
      .filter((format, index) => index <= 3);
      const formatsLabels = transformedFormats.map(
      (format) => format.formatOrChoice
    );
    transformedFormats = transformedFormats.map((format) => ({
      ...format,
      m2ComptageDAO: {
        c1: parseInt(format.m2ComptageDAO.c1 || 0),
        c2: parseInt(format.m2ComptageDAO.c2 || 0),
        c3: parseInt(format.m2ComptageDAO.c3 || 0),
        theorical: parseInt(format.m2ComptageDAO.theoretical || 0),
      },
    }));
    const values = transformedFormats.reduce((prev, curr) => {
      return [...prev, ...Object.values(curr.m2ComptageDAO)]
    },[])
    const maxValue = Math.max(...values);
    const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 3);
    this.canvas = document.getElementById("chartLineRedD");
    if (this.canvas) this.ctx = this.canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
    const data = {
      labels: formatsLabels,
      datasets: [
        this.inventoryConfigService.getConfigDataForFormatOrChoice(transformedFormats,"c1", '#a9d6e5'),
        this.inventoryConfigService.getConfigDataForFormatOrChoice(transformedFormats,"c2", '#468faf'),
        this.inventoryConfigService.getConfigDataForFormatOrChoice(transformedFormats,"c3", '#2a6f97'),
        this.inventoryConfigService.getConfigDataForFormatOrChoice(transformedFormats,"theorical", '#A1EDC9'),
       
      ],
    };

    this.charts.format = new Chart(this.ctx, {
      type: "bar",
      data,
      options: gradientBarChartConfigurationEtat,
    });
  };

  public getDataSite(site: String) {
    this.isLoading = true;
    this.dashboardService
      .getResource("/api/inventory/comptageC1C2/" + site)
      .subscribe(
        (data) => {
          this.isLoading = false;
          // @ts-ignore
          if(data.c1Statistics) {
            this.maitrageCurrenteVille = data.c1Statistics.totalMeterages;
            this.palettesCurrenteVille = data.c1Statistics.totalPalettes;
          }
          // @ts-ignore
          if(data.c2Statistics){
          this.maitrageCurrenteVille2 = data.c2Statistics.totalMeterages;
          this.palettesCurrenteVille2= data.c2Statistics.totalPalettes;
          }

          if(data.theoreticalStatistics){
            this.maitrageTheorique=data.theoreticalStatistics.totalMeterages;
            this.palettesTheorique=data.theoreticalStatistics.totalPalettes;
          }
        },
        (error) => {}
      );
    this.getDataForFirstCounts();
  }
  private getDataSite3(site: String) {
    const count = "Comptage 3";
    this.dashboardService
      .getResource("/api/inventory/comptageC3/" + site)
      .subscribe(
        (data) => {

          

          // @ts-ignore
          this.isLoading = false;
          if(data.c3Statistics){

            this.maitrageCurrenteVille3 = data.c3Statistics.totalMeterages;
            this.palettesCurrenteVille3= data.c3Statistics.totalPalettes;
          }

          if(data.theoreticalStatistics){

            this.maitrageTheorique=data.theoreticalStatistics.totalMeterages;
            this.palettesTheorique=data.theoreticalStatistics.totalPalettes;
          }
          // @ts-ignore
          this.etatParVille3 = data.stateSiteList1;
          // @ts-ignore
          this.formatParVille3 = data.formatSiteList1;
          // @ts-ignore
          this.choixParVille3 = data.choixSiteList1;
          // @ts-ignore
          this.storeParSite3 = data.storeSiteList1;
        },
        (error) => {}
      );
    this.getEtatParSiteC3(this.currentCity);
    this.getStoreParSiteC3(this.currentCity);
    this.getChoixParSiteC3(this.currentCity);
    this.getFormatParSiteC3(this.currentCity);
    this.getMaitrageParSiteC3(this.currentCity);
    this.getMetragesDataPerTypeStock(count);
    this.getMaitragePerStore(count);
    this.getPaletsDataByHour(count);
    this.getMetragesDataPerTypePalets(count);
  }

  private getMaitragePerStore = (count: string): void => {
    this.inventoryService.getMaitragePerMagazin(this.currentCity).subscribe((data) => {
      const labels = data.map((d) => d.formatOrChoice).filter((label, index) => index < 10);
      let stores = data.map((d) => parseInt(d.m2ComptageDAO[`c${count?.split(" ")[1]?.toString()}`] || 0))
      .sort((a, b) => b - a);
      stores = stores.filter((store, index) => index < 10);
      const maxValue = Math.max(...stores);
      const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 2);
      this.canvas = document.getElementById("CountryChart1");
      if (this.canvas) this.ctx = this.canvas.getContext("2d");
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#A9D6E5");
      gradientStroke.addColorStop(0.4, "#A9D6E5");
      gradientStroke.addColorStop(0, "#A9D6E5"); //red colors;
      this.charts.store = new Chart(
        this.ctx,
        this.configChart(
          labels,
          stores,
          gradientStroke,
          gradientBarChartConfigurationEtat,
          "#A9D6E5"
        )
      );
      });
  };

  private getPaletsPerStore = (): void => {
    this.inventoryService
      .getpaletsPerStore(this.currentCity)
      .subscribe((data:any) => {
        this.storesPerSite = data.map((store:any) => {
          return {
            name: store.store,
            value: Number(store.m2ComptageDAO.c3?.toFixed(2) || 0) 
          };
        });
      });
  };

  public getFormatParSite(site: String) {
    this.dashboardService
      .getResource("/api/inventory/formatParSite/" + site)
      .subscribe(
        (data) => {
          this.formatParVille = data;
          this.formats = _.map(this.formatParVille, "format");
          this.occurenceFormats = _.map(this.formatParVille, "occurence");
          this.initializeFormatChart();
        },
        (error) => {}
      );
  }
  public getChoixParSite(site: String) {
    this.dashboardService
      .getResource("/api/inventory/choixParSite/" + site)
      .subscribe((data) => {
        const transformedData = data.map(choix => ({ ...choix, choix: Number(choix.choix) })).sort((a, b) => a.choix - b.choix);
        this.choixParVille = data;
        this.choixs = transformedData.map(choix => choix.choix);
        this.occurenceChoix = transformedData.map(choix => choix.occurence);
        this.getChoixChart();
      });
  }
  public getStoreParSite(site: String) {
    this.dashboardService
      .getResource("/api/inventory/storeParSite/" + site)
      .subscribe((data) => {
        this.storeParSite = data;
        this.store = _.map(this.storeParSite, "store");
        this.occurenceStore = _.map(this.storeParSite, "occurence");
      });
  }
  //Data Palette C2
  public getMaitrageParSiteC2(site: String) {
    this.dashboardService
      .getResource("/api/inventory/maitrageParSiteC2/" + site)
      .subscribe(
        (data) => {
          this.maitrageCurrenteVille2 = data;
        },
        (error) => {}
      );
  }
  public getEtatParSiteC2(site: String) {
    this.dashboardService
      .getResource("/api/inventory/etatParSiteC2/" + site)
      .subscribe((data) => {
        const transformedData = data.sort((a,b) => b.occurence - a.occurence);
        this.etats = transformedData.map((state) => state.etat);
        this.occurenceEtats = transformedData.map((state) => state.occurence);
        this.initializeEtatChart();
      });
  }
  public getFormatParSiteC2(site: String) {
    this.dashboardService.getResource("/api/inventory/formatParSiteC2/" + site).subscribe((data) => {
        this.formatParVille2 = data;
        this.formats = _.map(this.formatParVille2, "format");
        this.occurenceFormats = _.map(this.formatParVille2, "occurence");
        this.initializeFormatChart();
      });
  }
  public getChoixParSiteC2(site: String) {
    this.dashboardService
      .getResource("/api/inventory/choixParSiteC2/" + site)
      .subscribe(
        (data) => {
          const transformedData = data.map(choix => ({ ...choix, choix: Number(choix.choix) })).sort((a, b) => a.choix - b.choix);
          this.choixParVille2 = data;
        this.choixs = transformedData.map(choix => choix.choix);
        this.occurenceChoix = transformedData.map(choix => choix.occurence);
          this.getChoixChart();
        });
  }

  public getMetragesDataPerTypeStock = (count?: string): void => {
    const data = {
      count: `c${count.split(" ")[1]?.toString()}`,
      site: this.currentCity,
    };
    this.inventoryService.getMetragesPerTypeStock(data).subscribe((data:any) => {

      const labels = data?.typeStocks;
      const m2Palettes = data?.m2Palettes?.map((type:any) => type.toFixed(2));
      const max = Math.max(...m2Palettes)
      const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(max + max / 3);
      this.canvas = document.getElementById("typeStockM2");
      if (this.canvas) this.ctx = this.canvas.getContext("2d");
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#468FAF");
      gradientStroke.addColorStop(0.4, "#468FAF");
      gradientStroke.addColorStop(0, "#468FAF"); //blue colors
      this.charts.tP = new Chart(
        this.ctx,
        this.configChart(
          labels,
          m2Palettes,
          gradientStroke,
          gradientBarChartConfigurationEtat,
          "#468FAF"
        )
      );
    });
  };

  public getStoreParSiteC2(site: String) {
    this.dashboardService
      .getResource("/api/inventory/storeParSiteC2/" + site)
      .subscribe(
        (data) => {
          this.storeParSite2 = data;
          this.store = _.map(this.storeParSite2, "store");
          this.occurenceStore = _.map(this.storeParSite2, "occurence");
        },
        (error) => {}
      );
  }
  //Data Palette C3//Verification Ville Current
  public getMaitrageParSiteC3(site: String) {
    this.dashboardService
      .getResource("/api/inventory/maitrageParSiteC3/" + site)
      .subscribe(
        (data) => {
          this.maitrageCurrenteVille3 = data;
        },
        (error) => {}
      );
  }
  public getEtatParSiteC3(site: String) {
    this.dashboardService
      .getResource("/api/inventory/etatParSiteC3/" + site)
      .subscribe((data) => {
        this.etatParVille3 = data;
        const transformedData = data.sort((a, b) => b.occurence - a.occurence);
        this.etatParVille3 = data;
        this.etats = transformedData.map(etat => etat.etat);
        this.occurenceEtats = transformedData.map(etat => etat.occurence);
        this.initializeEtatChart();
      });
  }

  private getEtatParSite = (site: string) => {
    this.dashboardService
      .getResource("/api/inventory/comptageC1C2/" + site)
      .subscribe((data) => {
        const transformedData = data.stateSiteList1.sort((a,b) => b.occurence - a.occurence);
        this.etats = transformedData.map((state) => state.etat);
        this.occurenceEtats = transformedData.map((state) => state.occurence);
        this.initializeEtatChart();
      });
  };

  public onResize = (event: any): void => {
    if (this.selectedGlobalCount !== "3e") this.getDataForFirstCounts();
    else this.getDataSite3(this.currentCity);
  };

  public getFormatParSiteC3(site: String) {
    this.dashboardService
      .getResource("/api/inventory/formatParSiteC3/" + site)
      .subscribe(
        (data) => {
          this.formatParVille3 = data;
          this.formats = _.map(this.formatParVille3, "format");
          this.occurenceFormats = _.map(this.formatParVille3, "occurence");
          this.initializeFormatChart();
        },
        (error) => {}
      );
  }
  public getChoixParSiteC3(site: String) {
    this.dashboardService
      .getResource("/api/inventory/choixParSiteC3/" + site)
      .subscribe(
        (data) => {
          const transformedData = data.map(choix => ({ ...choix, choix: Number(choix.choix) })).sort((a, b) => a.choix - b.choix);
          this.choixParVille3 = data;
        this.choixs = transformedData.map(choix => choix.choix);
        this.occurenceChoix = transformedData.map(choix => choix.occurence);
          this.getChoixChart();
        },
        (error) => {}
      );
  }
  public getStoreParSiteC3(site: String) {
    this.dashboardService
      .getResource("/api/inventory/storeParSiteC3/" + site)
      .subscribe((data) => {
        this.storeParSite3 = data;
        this.store = _.map(this.storeParSite3, "store");
        this.occurenceStore = _.map(this.storeParSite3, "occurence");
      });
  }

  getDataPalette(): void {
    this.getChoixParSite(this.currentCity);
  }

  ngOnInit(): void {
    this.getUserData();
    this.getActiveInventory();
    this.getCurrentSite();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initializeFormatChart = (): void => {
    const max = Math.max(...this.occurenceFormats);
    const gradientBarChartConfigurationEtat =  this.inventoryConfigService.getChartConfig(max + max / 3);
    this.canvas = document.getElementById("chartBig1");
    if (this.canvas) this.ctx = this.canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "#A9D6E5");
    gradientStroke.addColorStop(0.4, "#A9D6E5");
    gradientStroke.addColorStop(0, "#A9D6E5"); //red colors;
    const config = this.configChart(
      this.formats,
      this.occurenceFormats,
      gradientStroke,
      gradientBarChartConfigurationEtat,
      "#A9D6E5"
    );
    this.myChartData = new Chart(this.ctx, config);
  };

  private initializeEtatChart = (): void => {
    this.canvas = document.getElementById("CountryChart");
    if (this.canvas) this.ctx = this.canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    
    gradientStroke.addColorStop(1, "#A9D6E5");
    gradientStroke.addColorStop(0.4, "#A9D6E5");
    gradientStroke.addColorStop(0, "#A9D6E5"); //blue colors
    const etats = this.etats?.map((etat) => etat.toUpperCase());
    const maxValue = Math.max(...this.occurenceEtats);
    const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 3);
   this.charts.etat = new Chart(
      this.ctx,
      this.configChart(
        etats,
        this.occurenceEtats,
        gradientStroke,
        gradientBarChartConfigurationEtat,
        "#A9D6E5"
      )
    );
  };

  private getChoixChart = (): void => {
    // ---------------------------------------- Choix Chart ------------------------------
    this.canvas = document.getElementById("chartLineRed");
    if (this.canvas) this.ctx = this.canvas.getContext("2d");
    
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "#2A6F97");
    gradientStroke.addColorStop(0.4, "#2A6F97"); //green colors
    gradientStroke.addColorStop(0, "#2A6F97"); //green colors
    const choixs = this.choixs?.map((choix) => `choix ${choix}`.toUpperCase());
    const maxValue = Math.max(...this.occurenceChoix)
    const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 3);
    new Chart(
      this.ctx,
      this.configChart(
        choixs,
        this.occurenceChoix,
        gradientStroke,
        gradientBarChartConfigurationEtat,
        "#2A6F97"
      )
    );
  };

  public updateOptions(): void {
    this.myChartData.data.datasets[0].data = this.data;
    this.myChartData.update();
  }

  changeVille(site: string): void {
    this.hideDefault=true
    this.currentCity = site;
    this.router.navigate([], { queryParams: { site } });
    this.destroyCharts();
  }

  public changeComptage = (count: string): void => {
    const countType = count.split(" ")[0].split("/").length;
    this.isLoading = true;
    this.selectedGlobalCount = countType > 1 ? "1e/2e" : "3e";
    if (countType > 1) {
      this.getDataSite(this.currentCity);
    } else this.getDataSite3(this.currentCity);
  };

  private configChart = (
    labels: any[],
    data: any[],
    gradientStroke: any,
    gradientBarChartConfigurationEtat: any,
    borderColor: string
  ): any => {
    return {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: borderColor,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: borderColor,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: borderColor,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data,
          },
        ],
      },
      options: gradientBarChartConfigurationEtat,
    };
  };

  private getCurrentSite = (): void => {
    this.route.queryParams.subscribe((params) => {
      if (params?.site) {
       if (this.selectedGlobalCount === '3e') this.getDataSite3(params.site)
        this.currentCity = params.site;
        this.getPaletsDataByHour(this.selectedCountPerformance);
        this.getDataSite(this.currentCity);
        this.getFormatPerSite();
        this.getChoixPerSite();
      }
    });
    let source: any;
    if (this.sseService.addEventTaboard == 0) {
      this.sseService.addEventTaboard = 3;
      source = this.sseService.getEventSource(`${environment.api_url}/api/inventory/subscribe`) as any;
    }
    if (source) {
      source.addEventListener("LatestNews", (event) => {
        if (this.authentificationService.isAuthenticate()){
          this.getPaletsPerStore();
        }
      });
    }
  };

  private destroyCharts = (): void => {
    (this.charts.perf as any).destroy();
    (this.charts.choix as any).destroy();
    (this.charts.format as any).destroy();
    (this.charts.etat as any).destroy();
  }

  private getDataForFirstCounts = (): void => {
    this.selectCountFormat(this.selectedCountForFormat);
    this.selectCountEtat(this.selectedCountForEtat);
    this.selectCountChoix(this.selectedCountForChoice);
    this.selectCountStore(this.selectedCountForStore);
    this.selectCountTypeStock(this.selectedCountForStock);
    this.selectCountForPalets(this.selectedCountForPalets);
    this.selectCountForMaitrage(this.selectedCountForMaitrage);
    this.getPaletsDataByHour(this.selectedCountPerformance);
    this.getPaletsPerStore();
    this.getMetragesPerYear();
    this.getMaitragePerStore(this.selectedCountForMaitrage);
  };




}
