import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class OperationService {
    constructor(private http: HttpClient) { }
    
    public getOperations = (params: { page: string, size: string }): Observable<any> => {
        let httpParams = new HttpParams();
        for (const [key, value] of Object.entries(params)) httpParams = httpParams.set(key, value);
        return this.http.get(`${environment.api_url}/api/inventory/operations/page`, { params: httpParams })
    }

}