
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
import { MaterialModule } from '../material/material.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { ShortenPipe } from './pipe/shorten.pipe';
import { FooterComponent } from '../components/footer/footer.component';
// adding shared module to share reusable components or modules 
@NgModule({
    imports: [
        CommonModule, 
        FormsModule, 
        ReactiveFormsModule,
        NgxBootstrapConfirmModule,
        MaterialModule
    ],
    declarations: [ModalComponent, SpinnerComponent, ShortenPipe,FooterComponent],
    exports: [ModalComponent, MaterialModule, SpinnerComponent, ShortenPipe, FooterComponent],
})

export class SharedModule{}