import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inventory } from './../../../../service/inventory.service';
import { ActivatedRoute } from '@angular/router';

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthentificationService } from 'src/service/authentification.service';
import { InventoryService } from 'src/service/inventory.service';
import { Site, SiteService } from 'src/service/site.service';
import { HistoryConfigsService } from '../history-config.service';

@Component({
    selector: 'app-select-element',
    templateUrl: 'select.component.html',
    styleUrls: ['./select.component.css']
})

// adding select Component

export class SelectComponent implements OnInit {
    public InventoriesForm: FormGroup;
    public sites: Site[] = [];
    public isSubmitted = false;
    public defaultInventoryData = { inventoryName: '', site: '', count: '' };
    private user: any;
    public inventories: any[] = [];
    @Input('isData') isDataExist = false;
    @Output('onPassData') inventory = new EventEmitter<any>();
    constructor(
        private siteService: SiteService,
        private authenticationService: AuthentificationService, 
        private route: ActivatedRoute, 
        private inventorySl: InventoryService,
        private historyConfigService: HistoryConfigsService,
        private http: HttpClient) { }

    ngOnInit(): void {
        this.initializeInventory();
        this.getUserData();
        this.getDefinitiveInventories();
    }

    public submit = (): void => {
      this.inventory.emit(this.InventoriesForm.value);
      this.isSubmitted = true;
    }

    public onSelectInventory = (): void => {
        this.isSubmitted = false;
        this.InventoriesForm.patchValue({ site: '', count: '' });
    }

    public downloadDataCount3 = (): void => {
        const data = { inventoryName: this.InventoriesForm.value.inventoryName, site: (this.InventoriesForm.value.site as string).toLowerCase() };
        switch(data.site) {
            case 'Kenitra'.toLowerCase() :{
                data.site = 'KN';
              break;
            } 
            case 'Casablanca'.toLowerCase() :{
                data.site = 'CA';
              break;
            } 
            case 'Berrechid'.toLowerCase() :{
                data.site = 'BR';
              break;
            } 
            case 'Tetouan'.toLowerCase() :{
                data.site = 'TN';
              break;
            }
          }
          console.log(`${environment.api_url}/api/inventory/inventories/c3csv/${data.inventoryName}/${data.site}`);
        window.open(`${environment.api_url}/api/inventory/inventories/c3csv/${data.inventoryName}/${data.site}`)
        this.historyConfigService.isImportedFile.next(true);
    }

    public shouldDisableSearchButton = (): boolean =>
        this.InventoriesForm.get('inventoryName').value && this.InventoriesForm.get('site').value && this.InventoriesForm.get('count').value 

    public shouldDisplayAlertMessage = (): boolean => this.isSubmitted && !this.isDataExist
        
    private getSites = (): void => {
        this.siteService.getSites().subscribe(sites => {
            this.sites = sites;
            this.sites = this.sites?.filter(site => (this.user?.site.map(site => site.toUpperCase()) || []).includes(site?.site.toUpperCase()));
        })
    }

    private getUserData = (): void => {
        this.authenticationService.userData.subscribe(user => {
            this.user = user;
            this.getSites();

        })  
    }

    private getInventoryParams = (): void => {
        this.route.queryParams.subscribe((params: any) => {
            this.defaultInventoryData = params;
        })
    }

    private getDefinitiveInventories = (): void => {
        const state = 'closed'
        this.inventorySl.getClosureInventories(state).subscribe(inventories => {
            this.inventories = inventories;
            this.getInventoryParams();
            this.InventoriesForm.patchValue(this.defaultInventoryData)
        });
    }

    private initializeInventory = (): void => {
        this.InventoriesForm = new FormGroup({
            inventoryName: new FormControl(''),
            site: new FormControl(''),
            count: new FormControl('')
        })
    }
}