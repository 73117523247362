import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { InventoryService } from 'src/service/inventory.service';
import { HistoryConfigsService } from './history-config.service';

const configCharts = [
    { type: 'Comptage 1', name: 'performance' },
    { type: 'Comptage 1', name: 'state' },
    { type: 'Comptage 1', name: 'format' },
    { type: 'Comptage 1', name: 'choice' },
    { type: 'Comptage 1', name: 'store' },
]
@Component({
    templateUrl: 'history.component.html',
    styleUrls: ['./history.component.css']
})

export class HistoryComponent implements OnInit, OnDestroy {
    constructor(private inventorySl: InventoryService, private historyConfigService: HistoryConfigsService, private router: Router, private route: ActivatedRoute) { }
    public countType: { type: string, name: string }[] = configCharts;
    public inventoryData: any[] = [];
    public isLoading = false;
    public dates: string[] = [];
    public params: { inventoryName: string, site: string, count: string };
    public isDataExist = false;
    public isCount3: boolean;
    public chartsMessages = [
        { type: 'performance', message: '' },
        { type: 'format', message: '' },
        { type: 'choice', message: '' },
        { type: 'state', message: '' },
        { type: 'store', message: '' },
    ];
    ngOnInit() {
        this.historyInventory();
        this.initializePerformanceChart()
    }
    ngOnDestroy() {
        this.countType = [];
    }
    public catchData = (inventory: { inventoryName: string, site: string, count: any }): void => {
        this.router.navigate([], { queryParams: { ...inventory } });
        this.isCount3 = inventory.count  === '3e' ? true : false;
        this.initializePerformanceChart();
        this.initializePerformanceChart('format');
        this.initializePerformanceChart('choice');
        this.initializePerformanceChart('state');
        this.initializePerformanceChart('store');
        
    }

    public onSelectDate = (date: string): void => {
        this.initializeCanvas('performance');
        this.performanceChart(date);
    }

    public chooseComptage = (count?: string, name?: string): void => {
        const chartName = this.countType.find(chart => chart.name === name);
        // count);
        if (chartName) {
            chartName.type = count;
            switch (chartName.name) {
                case 'performance': {
                    this.initializeCanvas('performance');
                    this.performanceChart();
                    break;
                }
                case 'state': {
                    this.initializeCanvas('state');
                    this.paletteStateChat();
                    break;
                }
                case 'format': {
                    this.initializeCanvas('format');
                    this.paletteFormatChart();
                    break;
                }
                case 'choice' : {
                    this.initializeCanvas('choice');
                    this.choiceChart();
                    break;
                }
                case 'store': {
                    this.initializeCanvas('store');
                    this.storeChart();
                    break;
                }
                default: {
                    break;
                }
            }
        }

    }

    private performanceChart = (selectedDate?: string): void => {
        // hours
        // calculating the quantity of palettes per performances
        let palettesCountType = this.inventoryData.filter(inv => inv.counting_type?.split('')[1].toString() === this.countType.find(count => count.name === 'performance').type.split(' ')[1].toString())
            .map(inv => ({ count: inv.counting_type, date: inv.date_time.split(' ')[1].split(':')[0].toString(), quantity: inv.quantity, fullDate: inv.date_time.split(' ')[0] }));
        let times = [];
        const hours = ['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
        if (!selectedDate) {
            for (const time of hours)
            times.push(palettesCountType.filter(p => p.date === time).length);
        } else {
            palettesCountType = palettesCountType.filter(date => date.fullDate.split(' ')[0] === selectedDate);
            for (const time of hours)
            times.push(palettesCountType.filter(p => p.date === time).length);
        }
        this.dates = [... new Set(this.inventoryData.map(inv => inv.date_time.split(' ')[0]))];
        const maxLength = Math.max(...times);
        const chartConfig = this.historyConfigService.getPerformanceChart(maxLength);
        const canvas = document.getElementById("chartBig1D") as any;
        const ctx = canvas.getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(233,32,16,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(233,32,16,0.0)");
        gradientStroke.addColorStop(0, "rgba(233,32,16,0)"); //red colors;
        const maxTimes = times.reduce((prev, curr) => prev + curr, 0);
        if (maxTimes) {
            const data = this.chartConfigData(gradientStroke, '#ec250d', times, hours);
            const performanceChart = new Chart(ctx, { type: "line", data, options: chartConfig });
            const existedPerformanceChart = (configCharts.find(chart => chart.name === 'performance') as any);
            existedPerformanceChart.config = performanceChart;
        } else {
            const index = this.chartsMessages.findIndex(chart => chart.type === 'performance');
            this.chartsMessages[index].message = 'Aucune donnée trouvée pour la performance';
        }
    }

    private paletteFormatChart = (): void => {
        // calculating the quantity of palettes per formats
        const configChart = this.countType.find(count => count.name === 'format').type.split(' ')[1].toString();
        const filteredFormatsPerCount = this.inventoryData.filter(inv => inv.counting_type?.split('')[1].toString() === configChart);
        const formats = [...new Set(filteredFormatsPerCount.map(inv => inv.format))];
        const formatsData = [];
        for (const format of formats) {
            formatsData.push(filteredFormatsPerCount.filter(inv => inv.format.toString() === format).length);
        }
        const maxLength = Math.max(...formatsData);
        const gradientBarChartConfiguration = this.historyConfigService.getStatePaletteChart(maxLength);
        const canvas = document.getElementById("chartBig1");
        const ctx = (canvas as any).getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        if (formatsData.length) {
            const data = this.chartConfigData(gradientStroke, '#1f8ef1', formatsData, formats);
            const formatChart = new Chart(ctx, { type: 'bar', data, options: gradientBarChartConfiguration });
            const exitedConfigChart = (configCharts.find(chart => chart.name === 'format') as any);
            exitedConfigChart.config = formatChart;
        } else {
            const index = this.chartsMessages.findIndex(chart => chart.type === 'format');
            this.chartsMessages[index].message = 'Aucune donnée trouvée pour le format';
        }
    }

    private paletteStateChat = (): void => {
        // calculating the quantity of palettes per states
        const configChart = this.countType.find(count => count.name === 'state').type.split(' ')[1].toString();
        const filteredDataPerComptage = this.inventoryData.filter(inv => inv.counting_type?.split('')[1].toString() === configChart);
        const states = [...new Set([...filteredDataPerComptage.map(palette => palette.state)])].sort();
        const dataPerState = [];
        for (const state of states) dataPerState.push(filteredDataPerComptage.filter(inv => inv.state === state).length)
        const maxLength = Math.max(...dataPerState);
        const chartGreenConfig = this.historyConfigService.getStatePaletteChart(maxLength);
        const canvas = document.getElementById("CountryChart");
        const ctx = (canvas as any).getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        this.gradientStrokeConfig(gradientStroke);
        if (dataPerState.length) {
            const data = this.chartConfigData(gradientStroke, '#FF851B', dataPerState, states.map(state => state.toUpperCase()));
            const stateChart = new Chart(ctx, { type: "bar", data, options: chartGreenConfig });
            const exitedConfigChart = (configCharts.find(chart => chart.name === 'state') as any);
            exitedConfigChart.config = stateChart;
        } else {
            const index = this.chartsMessages.findIndex(chart => chart.type === 'state');
            this.chartsMessages[index].message = 'Aucune donnée trouvée pour l\'État';
        }

    }

    private choiceChart = (): void => {
        // calculating the quantity of palettes per choices 
        const configChart = this.countType.find(count => count.name === 'choice').type.split(' ')[1].toString();
        const filteredDataPerComptage = this.inventoryData.filter(inv => inv.counting_type?.split('')[1].toString() === configChart);
        const choices = [...new Set([...filteredDataPerComptage.map(palette => palette.choice)])].sort()
        const dataPerChoice = [];
        for (const choice of choices) dataPerChoice.push(filteredDataPerComptage.filter(inv => inv.choice === choice).length);
        const maxLength = Math.max(...dataPerChoice);
        const chartGreenConfig = this.historyConfigService.getStatePaletteChart(maxLength);
        const canvas = document.getElementById("choiceChart");
        const ctx = (canvas as any).getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        this.gradientStrokeConfig(gradientStroke);
        if (dataPerChoice.length) {
            const data = this.chartConfigData(gradientStroke, '#00d6b4', dataPerChoice, choices);
            const choiceChart = new Chart(ctx, { type: "bar", data, options: chartGreenConfig });
            const exitedConfigChart = (configCharts.find(chart => chart.name === 'choice') as any);
            exitedConfigChart.config = choiceChart; 
        } else {
            const index = this.chartsMessages.findIndex(chart => chart.type === 'choice');
            this.chartsMessages[index].message = 'Aucune donnée trouvée pour le choix.';
        }

    }

    private storeChart = (): void => {
        // calculating the quantity of palettes per stores
        const storeConfig = this.countType.find(config => config.name === 'store').type.split(' ')[1].toString();
        const dataPerStore = this.inventoryData.filter(inv => inv.counting_type.split('')[1].toString() === storeConfig);
        
        const stores = [...new Set(dataPerStore.map(store => store.store))];
        
        const dataPerStores = [];
        for (const count of stores) {
            dataPerStores.push(dataPerStore.filter(store => store.store === count).length)
        }

        let dataTransform:Array<{data:any,label:any}>=[]
        
        dataPerStores.forEach((obj, index) => { 
            let tem:any={data:obj,label:stores[index]}
            dataTransform.push(tem)
        });

        
        // limiter a 10 et trier les tableaux
        let ascendingDataTransform = dataTransform.sort((a, b) => Number(b.data) - Number(a.data));

        if(ascendingDataTransform.length>10)
        ascendingDataTransform=ascendingDataTransform.slice(0, 10)
        
        
        const maxLength = Math.max(...dataPerStore);
        const gradientBarChartConfiguration = this.historyConfigService.getStatePaletteChart(maxLength);
        const canvas = document.getElementById("CountryChart1");
        const ctx = (canvas as any).getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        if (dataPerStore.length) {
            const data = this.chartConfigData(gradientStroke, '#B10DC9', ascendingDataTransform.map(d=>d.data) as any[], ascendingDataTransform.map(label => `M ${label.label}`));
            
            const storeChart = new Chart(ctx, { type: 'bar', data, options: gradientBarChartConfiguration });
            const exitedConfigChart = (configCharts.find(chart => chart.name === 'store') as any);
            exitedConfigChart.config = storeChart; 
        } else {
            const index = this.chartsMessages.findIndex(chart => chart.type === 'store');
            this.chartsMessages[index].message = 'Aucune donnée trouvée pour le magasin';
        }

    }


    public shouldEnableComptage = (chartType: string, type: string): boolean =>
        this.countType.find(count => count.name === chartType).type === type

    private initializeCharts = (): void => {
        // default charts initializations
        this.performanceChart();
        this.paletteStateChat();
        this.paletteFormatChart();
        this.choiceChart();
        this.storeChart();
    }


    private historyInventory = (): void => {
        this.route.queryParams.subscribe((params: any) => {  
            this.isLoading = true;
            this.isDataExist = false;
            this.inventoryData = [];
            if (Object.values(params).length) {
                this.params = params;
                this.isDataExist = true;
                this.countType = this.countType.map(count => ({ ...count, type: `Comptage ${this.params.count.split('')[0].toString()}` }));
                this.isCount3 = this.params.count === '3e' ? true : false;
                const inventory = { inventoryName: params.inventoryName, site: params.site, countingType: (params as any).count?.split(' ')[0].split('/').length > 1 ? 'c1, c2' : 'c3' };
                this.inventorySl.findByInventory(inventory).subscribe((Inventory) => {
                    console.log(Inventory)
                    this.inventoryData = Inventory;
                    if (this.inventoryData.length) this.chartsMessages = this.chartsMessages.map(chart => ({ ...chart, message: ''}));
                    this.isLoading = false;
                    this.initializeCharts();
                });
            }
        })
    }

    public getChartMessage = (type: string) => this.chartsMessages.find(chart => chart.type === type).message; 

    private chartConfigData = (gradientStroke: any, borderColor: string, dataPerCounts: number[], labels: string[]) => {
        // returning all charts configuration
        return {
            labels,
            datasets: [
                {
                    label: "My First dataset",
                    fill: true,
                    backgroundColor: gradientStroke,
                    borderColor: borderColor,
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: borderColor,
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: borderColor,
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 4,
                    data: dataPerCounts
                }
            ]
        }
    }

    private gradientStrokeConfig = (gradientStroke: any): void => {
        gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
        gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
        gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors
    }

    // destroying the canvas to insert new ones 
    private initializeCanvas = (type: string) => (configCharts.find(chart => chart.name === type) as any)?.config?.destroy();

    private initializePerformanceChart = (type: string = 'performance'): void => {
            (configCharts.find(chart => chart.name === type) as any).config?.destroy();
    }
}
