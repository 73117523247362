import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "src/service/guards/auth-guard.service";
import { InventoryComponent } from '../pages/exemples/inventory/inventory.component';
import { InventoryListComponent } from "../pages/exemples/inventory/list/inventory-list.component";
import { OperationsComponent } from "../pages/exemples/operations/operations.component";
import { TaboardComponent } from '../pages/exemples/taboard/taboard.component';
import { HistoryComponent } from "./history/history.component";

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component : TaboardComponent,
        canActivate: [AuthGuardService]
      },
      // adding new route for history
      {
        path: "historique",
        component : HistoryComponent,
        canActivate: [AuthGuardService]

      },
      {
        path: 'traitement', 
        loadChildren: () => import('../pages/exemples/treatment-avant/treatment.module').then(m => m.TreatmentModule),
        canActivate: [AuthGuardService ]

      },
      {
        path: "inventaire",
        component : InventoryComponent,
        canActivate: [AuthGuardService],
        children: [
          { path: 'dashboard', component: InventoryComponent },
        ]
      },
      { path: 'inventaire/list', component: InventoryListComponent, canActivate: [AuthGuardService] },
      {
        path: "operations",
        component : OperationsComponent,
        canActivate: [AuthGuardService]

      },
      { path: "parametres",canActivate: [AuthGuardService] ,  loadChildren: () => import('../pages/exemples/settings/settings.module').then(m => m.SettingsModule) },
      {
        path: "rapporteurs",
        loadChildren: () => import('../pages/exemples/mobile-users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuardService]

      },
]

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ],
})

export class ComponentsRoutingModule {}