import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import Chart from "chart.js";
import { environment } from "src/environments/environment";
import {Site, SiteService } from "src/service/site.service";
import { AuthentificationService } from '../../../../service/authentification.service';
import { DashboardService } from '../../../../service/dashboard.service';
import { SseService } from '../../../../service/sse.service';
import { InventoryConfigService } from "../inventory/inventory.config.service";
import { TaboardConfigService } from "./taboard.config.service";


export interface State {
  bon: number;
  moyen: number;
  mauvais: number;
}

@Component({
  selector: 'app-taboard',
  templateUrl: './taboard.component.html',
  styleUrls: ['./taboard.component.scss']
})

export class TaboardComponent implements OnInit {
  @ViewChild('htmlData') htmlData:ElementRef;

  axeComptageTempo = ["Comptage 1", "Comptage 2", "Comptage 3"];
  axeComptage = ["Comptage 1", "Comptage 2", "Comptage 3", "Théorique"];
  comptages = ["Comptage 1", "Comptage 2"];
  axeSite = ['Kenitra', 'Casablanca', 'Berrechid', 'Tetouan'];
  zoningPerStore: any[] = [];
  view = [700, 500]
  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };


  public currentCity: string = "";
  public hideDefault:boolean=false;
  public ctx;
  public ecartGlob: number;
  public datasets: any;
  public data: any;
  public myChartData;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public clicked2: boolean = false;
  public colorScale: any;
  public choix: any[] = [];
  public metrageInventaire: { m1: number, m2: number, m3: number, mT: number };
  public selectedSite = { site: 'Supercerame', value: 0 };
  public isSelected: boolean;
  public kenitra: State;
  public casa: State;
  public berrechid: State;
  public tetouan: State;
  public metragePalette: { metrageKenitra: number, metrageBerrechid: number, metrageCasablanca: number, metrageTetouan: number };
  maitrageCasa: number;
  maitrageKenitra: number;
  maitrageBerchid: number;
  maitrageTetouan: number;
  maitrageCasa2: number;
  maitrageKenitra2: number;
  maitrageBerchid2: number;
  maitrageTetouan2: number;
  maitrageCasa3: number;
  maitrageKenitra3: number;
  maitrageBerchid3: number;
  maitrageTetouan3: number;
  nbrPalette: number;
  nbrFormats: number;
  nbrChoix: number;
  nbrPalette2: number;
  nbrFormats2: number;
  nbrChoix2: number;
  nbrPalette3: number;
  nbrFormats3: number;
  nbrChoix3: number;
  nbrPaletteKenitra: number;
  nbrPaletteCasa: number;
  nbrPaletteBerchid: number;
  nbrPaletteTetouan: number;
  nbrFormatKenitra: number;
  nbrFormatCasa: number;
  nbrFormatBerchid: number;
  nbrFormatTetouan: number;
  nbrChoixKenitra: number;
  nbrChoixCasa: number;
  nbrChoixBerchid: number;
  nbrChoixTetouan: number;
  nbrPaletteKenitra2: number;
  nbrPaletteCasa2: number;
  nbrPaletteBerchid2: number;
  nbrPaletteTetouan2: number;
  nbrFormatKenitra2: number;
  nbrFormatCasa2: number;
  nbrFormatBerchid2: number;
  nbrFormatTetouan2: number;
  nbrChoixKenitra2: number;
  nbrChoixCasa2: number;
  nbrChoixBerchid2: number;
  nbrChoixTetouan2: number;

  nbrPaletteTheorique: number;
  nbrFormatTheorique: number;
  nbrChoixTheorique: number;

  public HourPaletsChart;
  heures: any;
  nbrPalettesHeure: any;

  public TypePaletteChart;
  type: any;
  nbrPalettesType: any;

  public TypeStockChart;
  typeStocks: any;
  nbrPalettesStock: any;
  chart:any

  public comptage = 'c1';

  nbrPaletteKenitra3: number;
  nbrPaletteCasa3: number;
  nbrPaletteBerchid3: number;
  nbrPaletteTetouan3: number;
  nbrFormatKenitra3: number;
  nbrFormatCasa3: number;
  nbrFormatBerchid3: number;
  nbrFormatTetouan3: number;
  nbrChoixKenitra3: number;
  nbrChoixCasa3: number;
  nbrChoixBerchid3: number;
  nbrChoixTetouan3: number;
  public allsitesProgress:any
  public TotalsitesProgress:Array<any>=new Array<any>()
  public TotalsitesProgress2:Array<any>=new Array<any>()
  public CurentsitesProgress:Array<any>=new Array<any>()
  source: any;
   objectTest2:any =[{site:"1er Comptage",percentage:'80'},{site:"2éme Comptage",percentage:'100'},{site:"3éme Comptage",percentage:'50'}]
  options = {
    subtitleFormat: (percent: number): string => {
      return `${percent}%`;
    }
  }
  public sites: Site[];
   public isPie:boolean=false;
   public isPie2:boolean=false;


  constructor(
    public authentificationService: AuthentificationService,
    public dashboardService: DashboardService,
    private taboardConfigService: TaboardConfigService,
    private siteService: SiteService,
    private sseService: SseService,
    private inventoryConfigService: InventoryConfigService,
  ) {
    Object.assign(this, { single: this.zoningPerStore || [] });

  }

  getDashboard() {
    this.dashboardService.getResource('/api/inventory/dashboard')
      .subscribe(resp => {
        this.nbrPaletteTheorique = resp['nbrPaletteTheorique'];
        this.nbrFormatTheorique = resp['nbrFormatTheorique'];
        this.nbrChoixTheorique = resp['nbrChoixTheorique'];
        this.metrageInventaire = { m1: resp.maitrageInventaire, m2: resp.maitrageInventaire2, m3: resp.maitrageInventaire3, mT: resp.metrageTheorique };
        this.metragePalette = { metrageBerrechid: resp.nbrPaletteTheoriqueBerrechid, metrageKenitra: resp.nbrPaletteTheoriqueKenitra, metrageCasablanca: resp.nbrPaletteTheoriqueCasablanca, metrageTetouan: resp.nbrPaletteTheoriqueTetouan };
        this.nbrPalette = resp['nbrPalette'];
        this.nbrFormats = resp['nbrFormat'];
        this.nbrChoix = resp['nbrChoix']
        this.nbrPalette2 = resp['nbrPalette2']
        this.nbrFormats2 = resp['nbrFormat2']
        this.nbrChoix2 = resp['nbrChoix2']
        this.nbrPalette3 = resp['nbrPalette3']
        this.nbrFormats3 = resp['nbrFormat3']
        this.nbrChoix3 = resp['nbrChoix3']
        this.maitrageCasa = resp['maitrageCasa']
        this.maitrageKenitra = resp['maitrageKenitra']
        this.maitrageBerchid = resp['maitrageBerchid']
        this.maitrageTetouan = resp['maitrageTetouan']
        this.nbrPaletteKenitra = resp['nbrPaletteKenitra']
        this.nbrPaletteCasa = resp['nbrPaletteCasa']
        this.nbrPaletteBerchid = resp['nbrPaletteBerchid']
        this.nbrPaletteTetouan = resp['nbrPaletteTetouan']
        this.nbrFormatKenitra = resp['nbrFormatKenitra']
        this.nbrFormatCasa = resp['nbrFormatCasa']
        this.nbrFormatBerchid = resp['nbrFormatBerchid']
        this.nbrFormatTetouan = resp['nbrFormatTetouan']
        this.nbrChoixKenitra = resp['nbrChoixKenitra']
        this.nbrChoixCasa = resp['nbrChoixCasa']
        this.nbrChoixBerchid = resp['nbrChoixBerchid']
        this.nbrChoixTetouan = resp['nbrChoixTetouan']
        this.maitrageCasa2 = resp['maitrageCasa2']
        this.maitrageKenitra2 = resp['maitrageKenitra2']
        this.maitrageBerchid2 = resp['maitrageBerchid2']
        this.maitrageTetouan2 = resp['maitrageTetouan2']
        this.nbrPaletteKenitra2 = resp['nbrPaletteKenitra2']
        this.nbrPaletteCasa2 = resp['nbrPaletteCasa2']
        this.nbrPaletteBerchid2 = resp['nbrPaletteBerchid2']
        this.nbrPaletteTetouan2 = resp['nbrPaletteTetouan2']
        this.nbrFormatKenitra2 = resp['nbrFormatKenitra2']
        this.nbrFormatCasa2 = resp['nbrFormatCasa2']
        this.nbrFormatBerchid2 = resp['nbrFormatBerchid2']
        this.nbrFormatTetouan2 = resp['nbrFormatTetouan2']
        this.nbrChoixKenitra2 = resp['nbrChoixKenitra2']
        this.nbrChoixCasa2 = resp['nbrChoixCasa2']
        this.nbrChoixBerchid2 = resp['nbrChoixBerchid2']
        this.nbrChoixTetouan2 = resp['nbrChoixTetouan2']

        this.maitrageCasa3 = resp['maitrageCasa3']
        this.maitrageKenitra3 = resp['maitrageKenitra3']
        this.maitrageBerchid3 = resp['maitrageBerchid3']
        this.maitrageTetouan3 = resp['maitrageTetouan3']
        this.nbrPaletteKenitra3 = resp['nbrPaletteKenitra3']
        this.nbrPaletteCasa3 = resp['nbrPaletteCasa3']
        this.nbrPaletteBerchid3 = resp['nbrPaletteBerchid3']
        this.nbrPaletteTetouan3 = resp['nbrPaletteTetouan3']
        this.nbrFormatKenitra3 = resp['nbrFormatKenitra3']
        this.nbrFormatCasa3 = resp['nbrFormatCasa3']
        this.nbrFormatBerchid3 = resp['nbrFormatBerchid3']
        this.nbrFormatTetouan3 = resp['nbrFormatTetouan3']
        this.nbrChoixKenitra3 = resp['nbrChoixKenitra3']
        this.nbrChoixCasa3 = resp['nbrChoixCasa3']
        this.nbrChoixBerchid3 = resp['nbrChoixBerchid3']
        this.nbrChoixTetouan3 = resp['nbrChoixTetouan3'];
        this.dataChange();
        this.MetrageGlobalChart();
        this.getSites();
      })
  }

  public labelFormatting = (c) => `${(c.label)}`;

  // getPaletsByHour(): void {
  //   this.dashboardService.getResource('/api/inventory/palets/' + this.comptage + '/progress')
  //     .subscribe(resp => {
  //       this.heures = resp['heures'];
  //       this.nbrPalettesHeure = resp['nbrPalettesHeure'];
  //       if (this.HourPaletsChart) {
  //         this.HourPaletsChart.data.datasets[0].data = this.nbrPalettesHeure;
  //         this.HourPaletsChart.data.labels = this.heures;
  //         this.updateScales(this.HourPaletsChart);
  //       } else this.dataHourPaletsChartChange();
  //     });
  // }

  ngOnInit() {
    if (this.authentificationService.isAdmin()) {
      this.getPaletsByTypePalette();
      this.getPaletsByTypeStock();
      this.getAllChoixPerMaitrage();
      this.getMetragesPerSites();
      this.getAllMetragesPerEtat();
      this.getMaitragePerYear();
      this.getC3CorrectedWithSt();
      this.getMetragesPerClients();
    }
    this.getAllSites()
    this.getDashboard();
    if (this.sseService.addEventTaboard == 0) {
      this.sseService.addEventTaboard = 3;
      this.source = this.sseService
        .getEventSource(`${environment.api_url}/api/inventory/subscribe`)
    }
    if (this.source) this.source.addEventListener("LatestNews", (event) => {
      if (this.authentificationService.isAuthenticate() && window.location.hash == '#/dashboard') {
        this.getDashboard();
      }

    })

  }

  public backToListMaps = (): void => {
    this.getSites();
    this.selectedSite = { site: 'Supercerame', value:  0 };
    this.isSelected = false;
  }

  private getMetragesPerSites = (): void => {
    this.dashboardService.getMetragePerSites().subscribe(data => {
      const dataValues = (data as any[])?.reduce((prev, curr) => [ ...prev, ...curr.siteM2s ],[])
      const values = dataValues.map(d => Number(parseInt(d.m2)));
      const maxValue = Math.max(...values);
      const gradientBarChartConfiguration: any = this.taboardConfigService.getGradientBarChartConfiguration('white', (maxValue + maxValue / 4));
      const canvas4 = document.getElementById('MetrageSites') as any;////Palette Palette Palette Palette Palette
      if (canvas4) this.ctx = canvas4.getContext('2d');
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      let titles = (data as any[])?.reduce((prev, curr) => {
        return [...prev, ...curr.siteM2s.map(site => site.siteName)]
      }, [])
      titles = [...new Set(titles)]
 
      titles = [ 
        titles.find(title => title.toLowerCase() === 'Tetouan'.toLowerCase()) || 'Tetouan',
        titles.find(title => title.toLowerCase() === 'Berrechid'.toLowerCase()) || 'Berrechid',
        titles.find(title => title.toLowerCase() === 'Casablanca'.toLowerCase()) || 'Casablanca',
        titles.find(title => title.toLowerCase() === 'Kenitra'.toLowerCase()) || 'Kenitra',
       ];
      const c1Counts = data.filter(d => d.countingType === 'c1').map(site => ({ ...site, siteM2s: site.siteM2s.map(m2Site => ({ ...m2Site, m2: parseInt(m2Site.m2) })) }));
      const c2Counts = data.filter(d => d.countingType === 'c2').map(site => ({ ...site, siteM2s: site.siteM2s.map(m2Site => ({ ...m2Site, m2: parseInt(m2Site.m2) })) }));
      const c3Counts = data.filter(d => d.countingType === 'c3').map(site => ({ ...site, siteM2s: site.siteM2s.map(m2Site => ({ ...m2Site, m2: parseInt(m2Site.m2) })) }));
      const theoretical = data.filter(d => d.countingType === 'theoretical').map(site => ({ ...site, siteM2s: site.siteM2s.map(m2Site => ({ ...m2Site, m2: parseInt(m2Site.m2) })) }));
      const ecart = (c3Counts[0].siteM2s as any[]).reduce((prev, curr) => {
        const site = (theoretical[0].siteM2s as any[]).find(teo => teo.siteName === curr.siteName)
        if (site) {
          return [ ...prev, { siteName: site.siteName, m2: curr.m2 - site.m2 } ]
        }
      }, [])
      const ecartData = [{ countingType: 'ecart', siteM2s: ecart }];
      var myChart = new Chart(this.ctx, {
        type: 'bar',
        responsive: true,
        legend: {
          display: false
        },
        data: {
          labels: titles,
          datasets: [
            this.taboardConfigService.getMetragesDataPerSite(c1Counts,'#a9d6e5', gradientStroke),
            this.taboardConfigService.getMetragesDataPerSite(c2Counts,'#468faf', gradientStroke),
            this.taboardConfigService.getMetragesDataPerSite(c3Counts,'#2a6f97', gradientStroke),
            this.taboardConfigService.getMetragesDataPerSite(theoretical,'#A1EDC9', gradientStroke),
            this.taboardConfigService.getMetragesDataPerSite(ecartData,'#BF4126', gradientStroke),
          ]
        },
        options: gradientBarChartConfiguration
      });

    });
  }

  public getMetrageInventaire = (): number => 
  this.metrageInventaire?.m1 + this.metrageInventaire?.m2 + this.metrageInventaire?.m3+ this.metrageInventaire?.mT

  private getSites = (): void => {
    this.siteService.getSites().subscribe(sites => {
      this.zoningPerStore = sites.map((site: any) => {
        switch (site.site) {
          case 'Kenitra': {
            site = { name: 'KENITRA', value: this.maitrageKenitra3 }
            break;
          }
          case 'Casablanca': {
            site = { name: 'CASABLANCA', value: this.maitrageCasa3 };
            break;
          }
          case 'Berrechid': {
            site = { name: 'BERRECHID', value: this.maitrageBerchid3 };
            break;
          }
          case 'Tetouan': {
            site = { name: 'TETOUAN', value: this.maitrageTetouan3 };
            break;
          }
          case 'YnnaSteel': {
            site = { name: 'YNNASTEEL', value: 0 };
            break;
          }
        }
        return site;
      });
      this.selectedSite.value = Number(this.zoningPerStore.reduce((prev, curr) =>  prev + curr.value,0).toFixed(2));
    })
  }


  
  public onSelect = (site: any): void => {
    const currentSite = site.name;
    this.selectedSite = { site: site.name, value: site.value };
    this.isSelected = true;
    this.dashboardService.getStoresWithMetrages(currentSite).subscribe(stores => {
      const transformedStores = stores.map(store => ({
        name: store?.formatOrChoice,
        value: Number((store.m2ComptageDAO.c3?.toFixed(2) || 0))
      }));
      this.zoningPerStore = transformedStores;
    })
  }

  public MetrageGlobalChart = (): void => {
    const canvas = document.getElementById('MetrageGlobalChart1') as any;
    const globalMetrage = this.metrageInventaire.m3;
    const theorique = this.metrageInventaire.mT;
    this.ecartGlob = Number((globalMetrage - theorique).toFixed(2));

    const maxValue = Math.max(globalMetrage, theorique, this.ecartGlob);
    let  gradientChartOptionsConfigurationPurple1 = this.taboardConfigService.getGradientBarChartConfiguration('white', (maxValue + maxValue / 4));
    if (canvas) this.ctx = canvas.getContext('2d');
    const data = {
      labels: ['SUPERCERAME'],
      datasets: [
        this.taboardConfigService.getGlobalChartConfig([globalMetrage].map(value => value.toFixed(2)), '#0077b6'),
        this.taboardConfigService.getGlobalChartConfig([theorique].map(value => value.toFixed(2)), '#A1EDC9'),
        this.taboardConfigService.getGlobalChartConfig([this.ecartGlob], '#BF4126')
    ]
    }
    const chart = new Chart(this.ctx, { type: 'bar', data, options: gradientChartOptionsConfigurationPurple1 });
  }


  getPaletsByTypeStock(): void {
    this.dashboardService.getResource('/api/inventory/palets/c3/dashboard/typeStock/all')
      .subscribe(resp => {
        console.log(resp)
        this.typeStocks = resp.map(type => type.typeStock);
        this.nbrPalettesStock = resp?.map(e => e.totalM2);
        const total = this.nbrPalettesStock?.reduce((prev, curr) =>prev + curr  ,0);
        if (this.TypeStockChart) {
          this.TypeStockChart.data.datasets[0].data = this.nbrPalettesStock;
          this.TypeStockChart.data.labels = this.typeStocks;
          this.dataTypeStock();
        } else this.dataTypeStock()
      });
  }


  getPaletsByTypePalette(): void {
    this.dashboardService.getResource('/api/inventory/palets/c3/dashboard/typePalet/all')
      .subscribe(resp => {
        let typePalet=resp.map((item:any)=>item.typePalet)
        let totalM2=resp.map((item:any)=>item.totalM2)
        this.type = typePalet.map(type => type.toUpperCase());
        this.nbrPalettesType = (totalM2 as number[]).map(num => parseInt((num || 0)?.toString()));
        this.dataTypePalette();
      });
  }

  updateScales(chart): void {
    chart.options.scales = {
      xScale: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(233,32,16,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      yScale: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(233,32,16,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    };
    chart.update();
  }

  public getC3CorrectedWithSt = (): void => {
    this.dashboardService.getC3WithSt().subscribe(data => {
      const dataValues = (data as any[])?.reduce((prev, curr) => ([ ...prev, ...curr.siteM2s ]),[])
    const values = dataValues.map(d => Number(parseInt(d.m2)));
    const maxValue = Math.max(...values);
      const gradientBarChartConfiguration: any = this.taboardConfigService.getGradientBarChartConfiguration('white',(maxValue + maxValue / 4));
      const canvas = document.getElementById('MetrageSitesWithSt') as any;////Palette Palette Palette Palette Palette
      if (canvas) this.ctx = canvas.getContext('2d');
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      let titles = (data as any[]).reduce((prev, curr) => {
        return [ ...prev, ...curr.siteM2s.map(site => site.siteName) ]
      },[]);
      titles = [...new Set(titles)];
      titles = titles.map(title => title.toUpperCase());
      titles = [ 
        (titles.find(title => title === 'Tetouan'.toUpperCase()) || 'Tetouan'),
        (titles.find(title => title === 'Berrechid'.toUpperCase()) || 'Berrechid'),
        (titles.find(title => title === 'Casablanca'.toUpperCase()) || 'Casablanca'),
        (titles.find(title => title === 'Kenitra'.toUpperCase()) || 'Kenitra')
       ]
      const c3Counts = data.filter(d => d.countingType === 'c3').map(site => ({ ...site, siteM2s: site.siteM2s.map(m2Site => ({ ...m2Site, m2: parseInt(m2Site.m2) })) }));
      const theoretical = data.filter(d => d.countingType === 'theoretical').map(site => ({ ...site, siteM2s: site.siteM2s.map(m2Site => ({ ...m2Site, m2: parseInt(m2Site.m2) })) }));
      const ecart = (c3Counts[0].siteM2s as any[])?.reduce((prev, curr) => {
        const site = theoretical[0].siteM2s.find(site => site.siteName === curr.siteName);
        return [ ...prev, { siteName: curr.siteName, m2: (curr?.m2 - site?.m2 ) || null}]
      },[]);
      
      const EcartData = [{ countingType: 'ecart', siteM2s: ecart }];
      var myChart = new Chart(this.ctx, {
        type: 'bar',
        responsive: true,
        legend: {
          display: false
        },
        data: {
          labels: titles,
          datasets: [
            this.taboardConfigService.getMetragesDataPerSite(c3Counts,'#2a6f97', gradientStroke),
            this.taboardConfigService.getMetragesDataPerSite(theoretical,'#A1EDC9', gradientStroke),
            this.taboardConfigService.getMetragesDataPerSite(EcartData,'#BF4126', gradientStroke),
          ]
        },
        options: gradientBarChartConfiguration
      });
    })
  }


  // dataHourPaletsChartChange() {
  //   const gradientChartOptionsConfigurationWithTooltipRed = this.historyService.getPerformanceChart();
  //   const canvas = document.getElementById("chartHourPalette") as any;
  //   if (canvas) this.ctx = canvas.getContext("2d");
  //   const gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
  //   gradientStroke.addColorStop(1, "rgba(233,32,16,0.2)");
  //   gradientStroke.addColorStop(0.4, "rgba(233,32,16,0.0)");
  //   gradientStroke.addColorStop(0, "rgba(233,32,16,0)"); //red colors
  //   this.heures = this.heures.sort((a, b) => b - a);
  //   this.HourPaletsChart = new Chart(this.ctx, {
  //     type: "line",
  //     data: {
  //       labels: this.heures,
  //       datasets: [
  //         {
  //           label: "",
  //           fill: true,
  //           backgroundColor: gradientStroke,
  //           borderColor: "#ec250d",
  //           borderWidth: 2,
  //           borderDash: [],
  //           borderDashOffset: 0.0,
  //           pointBackgroundColor: "#ec250d",
  //           pointBorderColor: "rgba(255,255,255,0)",
  //           pointHoverBackgroundColor: "#ec250d",
  //           pointBorderWidth: 20,
  //           pointHoverRadius: 4,
  //           pointHoverBorderWidth: 15,
  //           pointRadius: 4,
  //           data: this.nbrPalettesHeure
  //         }
  //       ]
  //     },
  //     options: gradientChartOptionsConfigurationWithTooltipRed
  //   });
  //   this.updateScales(this.HourPaletsChart);
  // }

  dataTypePalette(): void {
    const canvas = document.getElementById("typePalette") as any;
    if (canvas) this.ctx = canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
    gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
    gradientStroke.addColorStop(0, 'rgba(29,140,248,0)');
    const maxValue = Math.max(...this.nbrPalettesType);
    const gradientChartOptionsConfigurationPurple = this.taboardConfigService.getGradientBarChartConfiguration('white', (maxValue + maxValue / 4));
    const configTypePalette = this.taboardConfigService.getConfigDataChart(this.type, this.nbrPalettesType, gradientStroke, gradientChartOptionsConfigurationPurple, '#1f8ef1', '#911300');
    this.TypePaletteChart = new Chart(this.ctx, configTypePalette);
  }

  public dataTypeStock = (): void => {
    const canvas = document.getElementById("typeStock") as any;
    if (canvas) this.ctx = canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
    gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
    gradientStroke.addColorStop(0, 'rgba(29,140,248,0)');
    const maxValue = Math.max(...this.nbrPalettesStock);
    const gradientChartOptionsConfigurationPurple = this.taboardConfigService.getGradientBarChartConfiguration('#06d3ea', (maxValue + maxValue / 4));
    var configTypeStock = this.taboardConfigService.getConfigDataChart(this.typeStocks, this.nbrPalettesStock, gradientStroke, gradientChartOptionsConfigurationPurple, '#1f8ef1', '#911300')
    this.TypeStockChart = new Chart(this.ctx, configTypeStock);
  }

  dataChange(): void {
    const canvas = document.getElementById("chartPalette") as any;
    if (canvas) this.ctx = canvas.getContext("2d");
    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "rgba(72,72,176,0.4)");
    gradientStroke.addColorStop(0.8, "rgba(72,72,176,0.2)");
    gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
    let data = [this.nbrPalette, this.nbrPalette2, this.nbrPalette3, this.nbrPaletteTheorique];
    const maxValue = Math.max(...data.map(d => Number(d)));
    const gradientChartOptionsConfigurationPurple = this.taboardConfigService.getGradientBarChartConfigurationPirple((maxValue + maxValue / 3));
    const config = this.taboardConfigService.getConfigDataChart(this.axeComptage, data, gradientStroke, gradientChartOptionsConfigurationPurple, '#ba54f5', '#be55ed');
    new Chart(this.ctx, config);
    const canvas2 = document.getElementById("chartBig2D") as any;
    if (canvas2) this.ctx = canvas2.getContext("2d");
    var gradientStroke = this.ctx?.createLinearGradient(0, 230, 0, 50);
    gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
    gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors
    const data3 = Object.values(this.metrageInventaire).map(v => v.toFixed(2)).map(d => Number(d));
    const maxInvValue = Math.max(...data3);
    const gradientBarChartConfigurationWithGrid = this.taboardConfigService.getGradientBarChartConfigurationGrid(maxInvValue + maxInvValue / 3);
    const config3 = this.taboardConfigService.getConfigDataChart(this.axeComptage, data3, gradientStroke, gradientBarChartConfigurationWithGrid, '#ff5991', '#ec250d')
    this.myChartData = new Chart(this.ctx, config3);
    if (this.authentificationService.isAdmin()) {
      const canvas4 = document.getElementById('CountryChartD') as any;////Palette Palette Palette Palette Palette
      if (canvas4) this.ctx = canvas4.getContext('2d')
      const maxValue = Math.max(...[
        this.nbrPaletteKenitra, this.nbrPaletteCasa, this.nbrPaletteBerchid, this.nbrPaletteTetouan,this.nbrPaletteKenitra2, 
        this.nbrPaletteCasa2, this.nbrPaletteBerchid2, this.nbrPaletteTetouan2,this.nbrPaletteKenitra3, this.nbrPaletteCasa3, 
        this.nbrPaletteBerchid3, this.nbrPaletteTetouan3, this.metragePalette.metrageBerrechid, this.metragePalette.metrageKenitra, 
      this.metragePalette.metrageCasablanca, this.metragePalette.metrageTetouan])
      const gradientBarChartConfiguration: any = this.taboardConfigService.getGradientBarChartConfiguration('white', (maxValue + maxValue / 3));
      const gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      const myChart = new Chart(this.ctx, {
        type: 'bar',
        responsive: true,
        legend: {
          display: false
        },
        data: {
          labels: this.axeSite,
          datasets: [
            this.taboardConfigService.getPaletteConfigChart(gradientStroke,[this.nbrPaletteKenitra, this.nbrPaletteCasa, this.nbrPaletteBerchid, this.nbrPaletteTetouan], '#a9d6e5'),
            this.taboardConfigService.getPaletteConfigChart(gradientStroke, [this.nbrPaletteKenitra2, this.nbrPaletteCasa2, this.nbrPaletteBerchid2, this.nbrPaletteTetouan2], '#468faf'),
            this.taboardConfigService.getPaletteConfigChart(gradientStroke,[this.nbrPaletteKenitra3, this.nbrPaletteCasa3, this.nbrPaletteBerchid3, this.nbrPaletteTetouan3], '#2a6f97'),
            this.taboardConfigService.getPaletteConfigChart(gradientStroke,[this.metragePalette.metrageKenitra, this.metragePalette.metrageCasablanca, this.metragePalette.metrageBerrechid, this.metragePalette.metrageTetouan], '#A1EDC9')
          ]
        },
        options: gradientBarChartConfiguration
      });
    }
  }

  private getAllChoixPerMaitrage = (): void => {
    this.dashboardService.getAllChoixPerMaitrage().subscribe(choix => {
      this.transformChoix(choix);
    })
  }


  private getSiteData = (data: any[], site: string) => {
    return {
      bon:  (parseInt(this.getSite(data[2].siteM2s, site, 'Bon Etat')?.m2) || 0),
      moyen: (parseInt(this.getSite(data[2].siteM2s, site, 'Etat Moyen')?.m2) || 0),
      mauvais:  (parseInt(this.getSite(data[2].siteM2s, site, 'Mauvais Etat')?.m2) || 0),
    }
  }

  public sumValues = (site: any): number => {
    if (site) return Object.values(site)?.reduce((acc:number, val:number) => acc + val, 0) as number;
  }
 
  private getAllMetragesPerEtat = (): void => {
    this.dashboardService.getMetragesPerState().subscribe(data => {
      const states = ['BON', 'MOYEN', 'MAUVAIS'];
      this.kenitra = this.getSiteData(data, 'Kenitra');
      this.casa = this.getSiteData(data, 'Casablanca');
      this.berrechid = this.getSiteData(data, 'Berrechid');
      this.tetouan = this.getSiteData(data, 'Tetouan');
      const canvas = document.getElementById('PieChartGradient2') as any;
      if (canvas) this.ctx = canvas.getContext('2d');
      const config = this.taboardConfigService.getChartPieOption();
      const chart = new Chart(this.ctx, this.taboardConfigService.getChartPieConfig(states, (this.casa as any), config))
      const canvas1 = document.getElementById('PieChart2') as any;
      if (canvas1) this.ctx = canvas1.getContext('2d');
      const chart1 = new Chart(this.ctx, this.taboardConfigService.getChartPieConfig(states, (this.kenitra as any), config))
      const canvas2 = document.getElementById('PieChart3') as any;
      if (canvas2) this.ctx = canvas2.getContext('2d');
      const chart2 = new Chart(this.ctx, this.taboardConfigService.getChartPieConfig(states, (this.berrechid as any), config))
      const canvas3 = document.getElementById('PieChart4') as any;
      if (canvas3) this.ctx = canvas3.getContext('2d');
      const chart3 = new Chart(this.ctx, this.taboardConfigService.getChartPieConfig(states, (this.tetouan as any), config))
    })

  }

  private getSite = (array: any[], site: string, state) => array.find(s => s.siteName === site && s.state?.toLowerCase() === state?.toLowerCase())

  private transformChoix = (choix: any[]): void => {
    let transformedChoix = choix.map(choix => ({ ...choix, formatOrChoice: choix.formatOrChoice })).sort((a, b) => a.formatOrChoice - b.formatOrChoice).filter((format, index) => index <= 3);
    const choixs = transformedChoix.map(ch => ch.formatOrChoice).map(ch => (`CHOIX ${ch}`));
    transformedChoix = transformedChoix.map(format => ({
       ...format, 
       m2ComptageDAO: { 
         c1: parseInt(format.m2ComptageDAO.c1 || 0), 
         c2: parseInt(format.m2ComptageDAO.c2 || 0), 
         c3: parseInt(format.m2ComptageDAO.c3 || 0), 
         theoretical: parseInt(format.m2ComptageDAO.theoretical),
         ecart: parseInt(String(format.m2ComptageDAO.c3)) - parseInt(String(format.m2ComptageDAO.theoretical))
        } }));
        const values = transformedChoix.map(choix => choix.m2ComptageDAO.theoretical);
        const max = Math.max(...values);
        const gradientBarChartConfigurationEtat = this.taboardConfigService.getGradientBarChartConfigurationRED('white',max + max / 2);
    const canvas = document.getElementById('chartLineGreenD') as any;
    if (canvas) this.ctx = canvas.getContext('2d');
    const gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
    const data = new Chart(this.ctx, {
      type: 'bar',
      responsive: true,
      legend: {
        display: false
      },
      data: {
        labels: choixs,
        datasets: [
          this.taboardConfigService.dataCharts(transformedChoix, 'c1', '#a9d6e5', gradientStroke),
          this.taboardConfigService.dataCharts(transformedChoix, 'c2', '#468faf', gradientStroke),
          this.taboardConfigService.dataCharts(transformedChoix, 'c3', '#2a6f97', gradientStroke),
          this.taboardConfigService.dataCharts(transformedChoix, 'theoretical', '#A1EDC9', gradientStroke),
          this.taboardConfigService.dataCharts(transformedChoix, 'ecart', '#BF4126', gradientStroke),
        ]
      },
      options: gradientBarChartConfigurationEtat
    });
  }

  // private getTop4Formats = (formats: any[]) => {
  //   let transformedFormats = formats.sort((a, b) => parseInt(b.totalC1C2) - parseInt(a.totalC1C2)).filter((format, index) => index <= 3);
  //   const formatsLabels = transformedFormats.map(format => format.formatOrChoice);
  //   transformedFormats = transformedFormats.map(format => ({ ...format, m2ComptageDAO: { c1: parseInt(format.m2ComptageDAO.c1), c2: parseInt(format.m2ComptageDAO.c2), c3: parseInt(format.m2ComptageDAO.c3) } }));
  //   const canvas = document.getElementById('chartLineRedD') as any;
  //   if (canvas) this.ctx = canvas.getContext('2d');
  //   var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
  //   const gradientBarChartConfiguration: any = this.taboardConfigService.getGradientBarChartConfigurationRED('white');
  //   const data = new Chart(this.ctx, {
  //     type: 'bar',
  //     responsive: true,
  //     legend: {
  //       display: false
  //     },
  //     data: {
  //       labels: formatsLabels,
  //       datasets: [
  //         this.taboardConfigService.dataCharts(transformedFormats, 'c1', '#ffc300', gradientStroke),
  //         this.taboardConfigService.dataCharts(transformedFormats, 'c2', '#0a9396', gradientStroke),
  //         this.taboardConfigService.dataCharts(transformedFormats, 'c3', '#b5179e', gradientStroke),
  //         this.taboardConfigService.dataCharts(transformedFormats, 'theoretical', '#f94144', gradientStroke),
  //       ]
  //     },
  //     options: gradientBarChartConfiguration
  //   });
  // }

  public updateOptions(comptage) {
    this.comptage = comptage;
    this.getPaletsByTypePalette();
    this.getPaletsByTypeStock();
  }

  private getMaitragePerYear = (): void => {
    this.dashboardService.getMaitragePerYear().subscribe(data =>  this.transformMétrageDataPerYear(data))
  }

  private getMetragesPerClients = (): void => {
    this.dashboardService.getMetragesPerTypeClients().subscribe(data => this.transformDataMetragesPerClients(data));
  }

  private transformDataMetragesPerClients = (data: any[]): void => {
    const labels = data.map(title => title.formatOrChoice);
      const clients = data.map((client) => ({
    ...client,
    m2ComptageDAO: {
      c1: parseInt(client.m2ComptageDAO.c1 || 0),
      c2: parseInt(client.m2ComptageDAO.c2 || 0),
      c3: parseInt(client.m2ComptageDAO.c3 || 0),
    },
  }));
  const values = (clients as any[]).reduce((prev, curr) => {
    return [ ...prev, ...Object.values(curr.m2ComptageDAO) ];
  }, [])
  const maxValue = Math.max(...values);
  const gradientBarChartConfigurationEtat = this.inventoryConfigService.getChartConfig(maxValue + maxValue / 2);
  const canvas = document.getElementById("MetragesPerClients") as any;
  if (canvas) this.ctx = canvas.getContext("2d");
  const desiredData = {
    labels,
    datasets: [
      this.inventoryConfigService.getConfigDataForFormatOrChoice(clients, "c1", '#a9d6e5'),
      this.inventoryConfigService.getConfigDataForFormatOrChoice(clients, "c2", '#468faf'),
      this.inventoryConfigService.getConfigDataForFormatOrChoice(clients, "c3", '#2a6f97'),
    ],
  };
  var myChart = new Chart(this.ctx, {
    type: "bar",
    data: desiredData,
    options: gradientBarChartConfigurationEtat,
  });

  }

  private transformMétrageDataPerYear = (dataPerYear: any[]): void => {
    let years = dataPerYear?.reduce((prev, curr) => [...prev, ...curr.yearM2List ], [])
    years = years.map(year => year.year).filter((year, index) => year >= 2019);
    years = [...new Set(years)];
    let transformedArray = dataPerYear.map(data => ({ ...data, yearM2List: data.yearM2List.map(year => ({ ...year, m2: parseInt(year.m2) })) }));
    let restDates = transformedArray.map(data => ({ ...data, yearM2List: data.yearM2List.filter((year, index) => year.year < 2019) }));
    restDates = restDates.map((date) => ({ ...date, yearM2List: { year: 'anciennes année', m2: date.yearM2List.reduce((prev, curr) => (prev + curr.m2), 0) } }));
    if (years.length)  years.push('2018 et antérieur');
    const theoretical = dataPerYear.find(data => data.countingType === 'theoretical').yearM2List?.map(year => year.m2);
    const maxValue = Math.max(...theoretical);
    const gradientBarChartConfigurationEtat = this.taboardConfigService.getGradientBarChartConfigurationRED('white',maxValue + maxValue / 2);
    const canvas = document.getElementById('metragePerYear') as any;
    if (canvas) this.ctx = canvas.getContext('2d');
    const desiredArray = dataPerYear.filter(d => d.countingType === 'c3' || d.countingType === 'theoretical');
    const ecart = (desiredArray[0].yearM2List as any[])?.reduce((prev, curr) => {
      const site = (desiredArray[1].yearM2List as any[]).find(teo => teo.year === curr.year)
        return [ ...prev, { year: site?.year, m2: (curr?.m2 || 0) - (site?.m2 || 0) } ]
    }, []);
    let newList = ecart?.map(year => {
      if (year.year <= 2018) return year;
      else return;
    })
    const total = (newList as any[]).filter(year => year?.year).reduce((prev, curr) => prev + curr?.m2, 0); 
    transformedArray.push({ countingType: 'ecart', yearM2List: ecart?.map(e => ({ ...e, m2: e.m2.toFixed(2) || 0 })) });
    if (transformedArray.length) restDates.push({ countingType: 'ecart', yearM2List: { year: '2018 et antérieur', m2: total.toFixed(2) }})
    const data = {
      labels: years,
      datasets: [
        this.taboardConfigService.getDataSetperYear('#a9d6e5', '#a9d6e5', transformedArray, restDates, 0),
        this.taboardConfigService.getDataSetperYear('#468faf', '#468faf', transformedArray, restDates, 1),
        this.taboardConfigService.getDataSetperYear('#2a6f97', '#2a6f97', transformedArray, restDates, 2),
        this.taboardConfigService.getDataSetperYear('#A1EDC9', '#A1EDC9', transformedArray, restDates, 3),
        this.taboardConfigService.getDataSetperYear('#BF4126', '#BF4126', transformedArray, restDates, 4),
      ]
    }
    const chart = new Chart(this.ctx, { type: 'bar', data, options: gradientBarChartConfigurationEtat });

  }

  onResize($event: any) {
    if (this.authentificationService.isAdmin()) {
      // this.getPaletsByHour();
      this.getPaletsByTypePalette();
      this.getPaletsByTypeStock();
    }
    this.getDashboard();
  }


  private getAllSites = (): void => {
    this.siteService.getSites().subscribe((sites) => {
      this.sites = sites;
      sites.map((site)=>{
        if(site.site.toLowerCase()==="kenitra".toLowerCase()) {
          this.currentCity = site.site;
        }
        return site
      })
      this.getDataProgressCercle();
    });
  };


  changeVille(event: any) {
    if(event!=null)
    this.currentCity=event.target.value as string
    if(this.allsitesProgress){
      let listTempo=Object.entries(this.allsitesProgress);
      listTempo.map((element:any)=> {
        if(element[0].toLowerCase()==this.currentCity.toLowerCase())
        {
          this.CurentsitesProgress=this.getTransformData(element[1])
        }
      })

      let titles=this.CurentsitesProgress.map(el=>el.title)
      let datatempo:number[] = this.CurentsitesProgress.map(el=>el.percentage)

      const maxValue = Math.max(...datatempo);
      const data = {
        labels: titles,
        datasets: this.taboardConfigService.getGlobalChartConfig(datatempo, '#0077b6')
      }

      const canvas = document.getElementById('progressperSite') as any;
      if (canvas) this.ctx = canvas.getContext("2d");
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "rgba(72,72,176,0.4)");
      gradientStroke.addColorStop(0.8, "rgba(72,72,176,0.2)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
      const gradientBarChartConfigurationEtat = this.taboardConfigService.getGradientBarChartConfigurationPirple(maxValue + maxValue / 2);
      const gradientChartOptionsConfigurationPurple = this.taboardConfigService.getGradientBarChartConfigurationPirple((maxValue + maxValue / 3));
      const config = this.taboardConfigService.getConfigDataChart(this.axeComptageTempo, data.datasets.data, gradientStroke, gradientChartOptionsConfigurationPurple, '#ba54f5', '#be55ed');
      new Chart(this.ctx, config);
    }
    this.hideDefault=true
  }


  getDataProgressCercle(){
    this.dashboardService.getProgressBarData().subscribe((res)=>{
      this.TotalsitesProgress=this.getTransformData(res.all)

      let titlesAll=this.TotalsitesProgress.map(el=>el.title)
      let datatempoAll:number[] = this.TotalsitesProgress.map(el=>el.percentage)
      const maxValueAll = Math.max(...datatempoAll);
      const dataAll = {
        labels: titlesAll,
        datasets: this.taboardConfigService.getGlobalChartConfig(datatempoAll, '#0077b6')
      }
      const canvas1 = document.getElementById('progress') as any;
      if (canvas1) this.ctx = canvas1.getContext("2d");
      var gradientStroke1 = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke1.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke1.addColorStop(0, 'rgba(29,140,248,0)');
      const gradientBarChartConfigurationEtat1 = this.taboardConfigService.getGradientBarChartConfiguration("#fff",maxValueAll + maxValueAll / 2);
      const gradientChartOptionsConfigurationPurple1 = this.taboardConfigService.getGradientBarChartConfiguration("#fff",(maxValueAll + maxValueAll / 3));
      const config1 = this.taboardConfigService.getConfigDataChart(this.axeComptageTempo, dataAll.datasets.data, gradientStroke1, gradientChartOptionsConfigurationPurple1, '#0077b6', '#fff');
      new Chart(this.ctx, config1);
      ///////////////////////////
      this.allsitesProgress=res
      let listTempo=Object.entries(res);
      listTempo.map((element:any)=> {
        if(element[0].toLowerCase()==this.currentCity.toLowerCase())
        {
          this.CurentsitesProgress=this.getTransformData(element[1])
        }
      });

      let titles=this.CurentsitesProgress.map(el=>el.title)
      let datatempo:number[] = this.CurentsitesProgress.map(el=>el.percentage)

      const maxValue = Math.max(...datatempo);
       const data = {
        labels: titles,
        datasets: this.taboardConfigService.getGlobalChartConfig(datatempo, '#0077b6')
      }

      const canvas = document.getElementById('progressperSite') as any;
      if (canvas) this.ctx = canvas.getContext("2d");
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "rgba(72,72,176,0.4)");
      gradientStroke.addColorStop(0.8, "rgba(72,72,176,0.2)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
      const gradientBarChartConfigurationEtat = this.taboardConfigService.getGradientBarChartConfigurationPirple(maxValue + maxValue / 2);
      const gradientChartOptionsConfigurationPurple = this.taboardConfigService.getGradientBarChartConfigurationPirple((maxValue + maxValue / 3));
      const config = this.taboardConfigService.getConfigDataChart(this.axeComptageTempo, data.datasets.data, gradientStroke, gradientChartOptionsConfigurationPurple, '#ba54f5', '#be55ed');
      this.chart=new Chart(this.ctx, config);
    })
  }


  switchToDashPie(type:string){
    if(type==="first"){
        this.isPie=!this.isPie
    }
    else if (type==="two"){
      this.isPie2=!this.isPie2
    }
  }

  getTransformData(obj:any){
    let TotalsitesProgress : Array<any>=[]
    let tempoc1:any={title:"1er Comptage",percentage:obj.C1_PALETTE.toFixed(2)}
    let tempoc2:any={title:"2éme Comptage",percentage:obj.C2_PALETTE.toFixed(2)}
    let tempoc3:any={title:"3éme Comptage",percentage:obj.C3_PALETTE.toFixed(2)}
    TotalsitesProgress.push(tempoc1)
    TotalsitesProgress.push(tempoc2)
    TotalsitesProgress.push(tempoc3)
    return TotalsitesProgress
  }

}
